import React from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT } from 'baseui/popover';
import { useTranslation } from 'react-i18next';

import Popover from 'components/UI/ENGTTooltip/ENGTTooltip';

import { IObjProps } from 'shared/consts/types';
import TooltipIcon from 'shared/icons/node/Tooltip.svg';

export interface IFormLabelProps {
	className?: IObjProps;
	id?: string;
	label: string;
	tooltip?: string | React.ReactElement;
	fontSize?: string;
	isRequired?: boolean;
	modal?: boolean;
	markOptional?: boolean;
	color?: string;
	placement?: keyof PLACEMENT;
	setNestedTooltipHovered?: Function;
}

function FormLabel({
	id,
	label,
	tooltip = '',
	fontSize = '0.875rem',
	className = {},
	isRequired = false,
	modal,
	markOptional = false,
	color,
	placement = PLACEMENT.right,
	setNestedTooltipHovered,
}: IFormLabelProps) {
	const [css, theme]: any = useStyletron();

	return (
		<span
			className={css({
				display: 'block',
				fontWeight: 500,
				fontSize,
				lineHeight: '1.25rem',
				marginBottom: '0.25rem',
				color: color || (modal ? theme.modalTextColor : theme.colors.primaryA),
				...className,
			})}
		>
			{label}
			{isRequired && (
				<span
					className={css({
						lineHeight: 0,
						top: '0.1325rem',
						position: 'relative',
						color: theme.colors.dotPendingFill,
						verticalAlign: 'super',
						display: 'inline-block',
					})}
				>
					*
				</span>
			)}
			{tooltip && (
				<Popover
					content={tooltip}
					ignoreBoundary
					placement={placement}
					setNestedTooltipHovered={setNestedTooltipHovered}
				>
					<img alt='tooltip' src={TooltipIcon} className={css({ cursor: 'pointer', marginLeft: '0.5rem' })} />
				</Popover>
			)}
			{markOptional && <LabelOptional />}
		</span>
	);
}

export const LabelOptional = () => {
	const [css, theme]: any = useStyletron();

	const { t } = useTranslation(['common']);

	return (
		<span
			className={css({
				fontStyle: 'normal',
				fontWeight: 400,
				fontSize: '1rem',
				lineHeight: '1.25rem',
				color: theme.colors.accent50,
				marginLeft: '2px',
			})}
		>
			({t('common:optional')})
		</span>
	);
};

export default React.memo(FormLabel);

/* eslint-disable import/prefer-default-export */
import TagManager from 'react-gtm-module';

import { addSupportBot } from 'components/SupportBot/SupportBots';

import { ENGATI_ROLES } from 'shared/consts/consts';
import { getUniqueLSKey, LS_KEYS, writeToLs } from 'shared/consts/localStorageConsts';
import { GA_EVENTS } from 'shared/consts/OnBoarding';
import { IObjProps } from 'shared/consts/types';
import { isEmpty } from 'shared/helpers';

import { getAgentOnlineStatusAction, getBotDetailsAction } from 'store/App/Bot';
import { getCurrentPlanAction, getCustomerDetailsAction } from 'store/App/Plan';
import { getAgentSettings, getThemeSettingsAction } from 'store/App/Preferences/actions';
import {
	getBotAdminAction,
	getIfCustomerExistsOnStripe,
	getJouneryPhaseData,
	getProfileDetailsAction,
} from 'store/App/User';
import { getJourneyTemplates } from 'store/Broadcast';
import { RootState } from 'store/rootReducer';

import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

const { USER_ID_TRACK } = GA_EVENTS;
const LOGIN_LINKS = [NoColumnRouteLinks.whatsappSetup];

export const onboardingAPIs =
	(check = true) =>
	(dispatch: any, getState: () => RootState) => {
		const adminDetails = dispatch(getBotAdminAction()).then((resp: any) => {
			if (resp.data) {
				dispatch(getAgentSettings());
				TagManager.dataLayer({
					dataLayer: {
						userId: resp.data.uid,
						event: USER_ID_TRACK,
					},
				});
				check && resp.data?.is_engati_brand && redirectForPlgWhatsappSetup(resp.data);
			}

			return resp;
		});
		const botDetails = dispatch(getBotDetailsAction());
		const profileDetails = dispatch(getProfileDetailsAction());
		const planDetails = dispatch(getCurrentPlanAction());
		dispatch(getThemeSettingsAction());
		dispatch(getAgentOnlineStatusAction());
		dispatch(getIfCustomerExistsOnStripe());
		const customerPlanDetails = getState().Plan;

		Promise.all([adminDetails, botDetails, profileDetails, planDetails]).then(
			([admin, bot, profile, plan]: any) => {
				if (admin?.data?.is_engati_brand && admin?.data?.supportBotEnabled) {
					dispatch(addSupportBot());
				}
				if (profile?.data?.roleName === ENGATI_ROLES.ROLE_CONNECT_OWNER) {
					dispatch(getCustomerDetailsAction());
				}
				if (admin?.data?.enable_whatsapp_accelerator) {
					dispatch(getJourneyTemplates());
				}
			}
		);

		const redirectForPlgWhatsappSetup = (response: IObjProps) => {
			if (response.enable_whatsapp_accelerator) {
				const { uid } = response;

				if (uid) {
					writeToLs(getUniqueLSKey(LS_KEYS.IS_MOBILE_TO_DESKTOP_MODAL_SHOWN, '', uid), false);
				}

				dispatch(getJouneryPhaseData()).then((resp: any) => {
					if (
						!(
							resp?.data?.responseObject?.botRef ||
							LOGIN_LINKS.some((link) => window.location.href.indexOf(link) > -1)
						)
					) {
						window.location.href = `/admin/wa-phone?email=${response.email}`;
					}
				});
			} else {
				const isEngatiCustomer = customerPlanDetails?.details?.data?.parentCustomerId === 1;
				dispatch(getJouneryPhaseData()).then((resp: any) => {
					const responseObject = resp?.data?.responseObject;
					if (
						!responseObject?.onboardingComplete &&
						responseObject?.productVertical === null &&
						isEngatiCustomer &&
						isEmpty(customerPlanDetails?.current.data)
					) {
						const productVerticalPageUrl = '/admin/get-started/usage-info';
						if (window.location.pathname != productVerticalPageUrl) {
							window.location.pathname = productVerticalPageUrl;
						}
					}
				});
			}
		};

		return adminDetails;
	};

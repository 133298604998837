import { PAGING_DEFAULT_OBJ } from 'shared/consts/consts';
import { IApiDataProps, IObjProps, IPaginatedApiStateProps, IPaginationState } from 'shared/consts/types';

export interface ImessageListStateInterface {
	allAgents: IApiDataProps<Array<IObjProps>>;
	allTabAgents: IApiDataProps<Array<IObjProps>>;
	userList: IPaginatedApiStateProps<Array<IObjProps>, IPaginationState>;
	selectedUser: IObjProps;
	conversationData: IObjProps;
	showStartConversationButton: boolean;
	messageCountMap: IObjProps;
	conversationReadStatusMap: IObjProps;
	usersCount: IObjProps;
	sentiment: IObjProps;
	agent: IObjProps;
	pathList: Array<IObjProps>;
	botLevelTagsList: Array<string> | null;
	userLevelTags: Array<string>;
}

export const messageListState: ImessageListStateInterface = {
	allAgents: {
		data: [],
		loading: false,
		error: false,
	},
	allTabAgents: {
		data: [],
		loading: false,
		error: false,
	},
	userList: {
		data: [],
		error: false,
		loading: false,
		pagination: PAGING_DEFAULT_OBJ,
		tab: '',
	},
	selectedUser: {},
	conversationData: {},
	showStartConversationButton: true,
	messageCountMap: {},
	conversationReadStatusMap: {},
	usersCount: {},
	sentiment: {
		data: {},
		attributeId: '',
	},
	agent: {},
	pathList: [],
	botLevelTagsList: null,
	userLevelTags: [],
};

import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

// const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const getDeveloperSettingsUrl = '/getApplications';
const addNewApplicationUrl = '/addNewApplication';
const revokeTokenUrl = '/revokeToken';
const renewTokenUrl = '/renewToken';

// const genericProxyApi = new BaseService(genericProxyRequestUrl);
const getDeveloperSettingsApi = new BaseService(getDeveloperSettingsUrl);
const addNewApplicationApi = new BaseService(addNewApplicationUrl);
const revokeTokenApi = new BaseService(revokeTokenUrl);
const renewTokenApi = new BaseService(renewTokenUrl);

export const EngatiApi = {
	getAppList() {
		return getDeveloperSettingsApi.getRequest();
	},
	addnewApp(params, data) {
		return addNewApplicationApi.postRequest(data, serialize(params, '?'));
	},
	revokeToken(data) {
		return revokeTokenApi.postRequest(data);
	},
	renewToken(data) {
		return renewTokenApi.postRequest(data);
	},
};

import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';
import { Overflow } from 'baseui/icon';
import { toaster } from 'baseui/toast';
import { PLACEMENT } from 'baseui/tooltip';
import { LabelSmall } from 'baseui/typography';
import CheckboxTree from 'react-checkbox-tree';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import Layout, { LayoutContent, LayoutHeader } from 'components/Common/Layout';
import ContactSalesModal from 'components/ContactModal/ContactModal';
import BroadcastSort from 'components/shared/BroadcastRevamp/BroadcastSort';
import CreateBroadcastBtn from 'components/shared/CreateBroadcastBtn/CreateBroadcastBtn';
import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTHeader from 'components/UI/ENGTHeader/ENGTHeader';
import usePaginationHook from 'components/UI/ENGTPagination/PaginationHook';
import ENGTTable, { ITableColumnProps } from 'components/UI/ENGTTable/ENGTTable';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';
import InfoModalBase from 'components/UI/Modal/InfoModal/InfoModalBase';
import { redirectFromModalAlert } from 'components/UI/Modal/ModalAlert/helper';
import ModalAlert from 'components/UI/Modal/ModalAlert/ModalAlert';
import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';
import PageHeader, { PageSubHeading } from 'components/UI/PageHeader/PageHeader';

import { renderWhatsappTemplateNote } from 'shared/consts/broadcast/constants';
import { BLOCK_BROADCAST_USER_DOWNLOAD_BEFORE } from 'shared/consts/consts';
import { DOC_LINKS_V2 } from 'shared/consts/DockLinks';
import { writeToLs } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';
import {
	BROADCAST_RETRY_TYPE,
	BROADCAST_STATUS,
	BROADCAST_TYPE,
	CHANNELS,
	LOCAL_STORAGE_VALUES,
	segmentOptions,
} from 'shared/enum';
import { GTMTrackingIds } from 'shared/enum/trackingEnums';
import {
	isValidResponseObject,
	PLATFORM,
	timeIntervalExceedsFiveDays,
	timeIntervalExceedsSevenDays,
	timeIntervalExceedsThreeHours,
	WHATSAPP_PROVIDERS,
} from 'shared/helpers';
import {
	assignColorsForSplitBroadcast,
	AutoRetryHoverMessageKey,
	getChannel,
	getChannelTooltip,
	modifyChannels,
	splitBroadcastHoverText,
} from 'shared/helpers/broadcastHelpers';
import useAccountStatusHook from 'shared/hooks/feature/useAccountStatusHook';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import AddIcon from 'shared/icons/AddIcon';
import AutoRetryStatusIcon from 'shared/icons/Broadcast/AutoRetryStatusIcon';
import CompletedIcon from 'shared/icons/Broadcast/CompletedIcon';
import FailedIcon from 'shared/icons/Broadcast/FailedIcon';
import InProgressIcon from 'shared/icons/Broadcast/InProgressIcon';
import NoBroadCastIcon from 'shared/icons/Broadcast/NoBroadCast.svg';
import PendingOnWhatsAppIcon from 'shared/icons/Broadcast/PendingOnWhatsAppIcon';
import PreparingIcon from 'shared/icons/Broadcast/PreparingIcon';
import RetryingIcon from 'shared/icons/Broadcast/RetryingIcon';
import RetryStoppedIcon from 'shared/icons/Broadcast/RetryStoppedIcon';
import SendingOnWhatsAppIcon from 'shared/icons/Broadcast/SendingOnWhatsAppIcon';
import StoppedIcon from 'shared/icons/Broadcast/StoppedIcon';
import StoppingIcon from 'shared/icons/Broadcast/StoppingIcon';
import CheckboxActive from 'shared/icons/CheckboxActive_ICON';
import CheckboxInactive from 'shared/icons/CheckboxInactive_ICON';
import CheckboxPartialActive from 'shared/icons/CheckboxPartialActive_ICON';
import DownloadIcon from 'shared/icons/DownloadIcon';
import LinkedSplitBroadcastIcon from 'shared/icons/LinkedSplitBroadcastIcon';
import { sendButtonClickEventToGA4 } from 'shared/trackingHelpers';

import { updateFirstBroadcastJourneyData } from 'store/App/User';
import {
	fontStyleSelector,
	isBroadcastV2EntitlementEnabled,
	isSandboxConfiguredSelector,
	isSandboxEntitlementEnabled,
	isWhatsappAcceleratorTrialEnabled,
	isWhatsAppConfiguredSelector,
	journeyPhaseSelector,
	marketingCampaignsAnalyticsSelector,
} from 'store/App/User/selectors';
import { botRefSelector, configuredChannelsForBot, providerConfigured } from 'store/Bot/selectors';
import {
	broadcastFilterActions,
	getPublishedBroadcastAction,
	modifyBroadcastDataAction,
	setBroadcastDataSortAction,
} from 'store/Broadcast';
import { API } from 'store/Broadcast/api';
import { publishedBroadcastList, publishedSort } from 'store/Broadcast/selectors';
import { RootState } from 'store/rootReducer';
import { customerIdSelector, isEngatiBrandSelector } from 'store/Users/selectors';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';
import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';

import BroadcastFilter from './components/BroadcastFilter';
import BroadcastMenu from './components/BroadcastMenu';
import { BroadcastSuccessWithNudge } from './components/BroadcastSuccessModal';
import RetryBroadcastModal, { IBroadcastHelpers } from './components/RetryBroadcastModal';
import StopBroadcastModal from './components/StopBroadcastModal';
import WarningModal from './components/WarningModal';
import WhatsappConfigurationModal from './components/WhatsappConfigurationModal';
import useBroadcastListHook from './hooks/useBroadcastListHook';
import useDefaultColumnsHook from './hooks/useDefaultColumnsHook';
import useRefreshBroadcastHook from './hooks/useRefreshBroadcastHook';
import useStopBroadcastHook from './hooks/useStopBroadcastHook';

import 'components/shared/BroadcastRevamp/CheckboxTree.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './index.scss';

const PREDEFINED_SEGMENTS = {
	SENT: 'SENT',
	UNDELIVERED: 'UNDELIVERED',
	DELIVERED: 'DELIVERED',
	UNREAD: 'UNREAD',
	READ: 'READ',
	NO_RESPONSE: 'NO_RESPONSE',
	REPLIED: 'REPLIED',
	CLICKED: 'CLICKED',
};

function BroadcastHistory() {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);
	const dispatch = useDispatch<any>();
	const navigate = useNavigate();

	const { usersListLink, usersContactsListLink } = TwoColumnRouteLinks;

	const [selectedBroadcastId, setSelectedBroadcastId] = useState<string>('');
	const [selectedBroadcastData, setSelectedBroadcastData] = useState<IObjProps>({});
	const [defaultColumns, setDefaultColumns] = useState<IObjProps>({});
	const [columns, setColumns] = useState<IObjProps>({});
	const [segmentDetails, setSegmentDetails] = useState<IObjProps>({});
	const [segmentUserCount, setSegmentUserCount] = useState<IObjProps>({});
	const [broadcastColumns, setBroadcastColumns] = useState<Array<ITableColumnProps>>([]);
	const [checked, setChecked] = useState<Array<string>>([]);
	const [values, setValues] = useState<Array<string>>([]);
	const [adminActions, setAdminActions] = useState<any>([]);
	const [segmentNodes, setSegmentNodes] = useState<any>([]);
	const [whatsappNotConfigured, setWhatsappNotConfigured] = useState<boolean>(false);
	const [isStopModalOpen, toggleStopModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [hasDialog360Provider, setHasDialog360Provider] = useState<boolean>(false);
	const [idColorMap, setIdColorMap] = useState<any>({});
	const [viewNoUsersModal, setViewNoUsersModal] = useState<boolean>(false);
	const [noDataForRetarget, setNoDataForRetarget] = useState<boolean>(false);
	const [isRetargetBroadcastOpen, setIsRetargetBroadcastOpen] = useState<boolean>(false);
	const [retryBroadcastModal, setRetryBroadcastModal] = useState<boolean>(true);
	const [totalUsersTargetted, setTotalUsersTargetted] = useState<number>(0);
	const [showContactSupport, setShowContactSupport] = useState<boolean>(false);
	const [expanded, setExpanded] = useState([
		PREDEFINED_SEGMENTS.SENT,
		PREDEFINED_SEGMENTS.UNDELIVERED,
		PREDEFINED_SEGMENTS.DELIVERED,
		PREDEFINED_SEGMENTS.UNREAD,
		PREDEFINED_SEGMENTS.READ,
		PREDEFINED_SEGMENTS.CLICKED,
	]);

	const broadcastHelpers = useBroadcastListHook();
	const [fetchAccountStatus, accountStatus, warning, showErrorModal, setShowErrorModal] = useAccountStatusHook({
		publishedPath: 'published',
	});
	const { getDefaultColumns } = useDefaultColumnsHook({ setColumns, setDefaultColumns });
	const { stopBroadcast } = useStopBroadcastHook();
	const { refreshBroadcast } = useRefreshBroadcastHook();

	const broadcastData = useSelector(publishedBroadcastList);
	const sort = useSelector(publishedSort);
	const { data, pagination: broadcastPagination, isLoading } = broadcastData;
	const isWhatsappConfigured = useSelector(isWhatsAppConfiguredSelector);
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const configuredChannels = useSelector(configuredChannelsForBot);
	const botRef = useSelector(botRefSelector);
	const cid = useSelector(customerIdSelector);
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const isSandboxEnabled = useSelector(isSandboxEntitlementEnabled);
	const isWhatsappSandboxConfigured = useSelector(isSandboxConfiguredSelector);
	const fontStyle: string = useSelector(fontStyleSelector);
	const isBroadcastV2Enabled = useSelector(isBroadcastV2EntitlementEnabled);
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);
	const journeyPhaseDetails = useSelector(journeyPhaseSelector);
	const provider = useSelector(providerConfigured);
	const supportEmailOrLink = useSelector((state: RootState) => state.User.botAdmin.data.support_email);

	const { isSmallDesktopScreen } = useResponsiveSize();

	const allRead = [PREDEFINED_SEGMENTS.NO_RESPONSE, PREDEFINED_SEGMENTS.REPLIED];
	const allDelivered = [PREDEFINED_SEGMENTS.UNREAD, ...allRead];
	const allSent = [PREDEFINED_SEGMENTS.UNDELIVERED, ...allDelivered];

	const treeHierarchy: IObjProps = {
		1: [PREDEFINED_SEGMENTS.SENT],
		2: [PREDEFINED_SEGMENTS.UNDELIVERED, PREDEFINED_SEGMENTS.DELIVERED],
		3: [PREDEFINED_SEGMENTS.UNREAD, PREDEFINED_SEGMENTS.READ],
		4: [PREDEFINED_SEGMENTS.NO_RESPONSE, PREDEFINED_SEGMENTS.REPLIED, PREDEFINED_SEGMENTS.CLICKED],
	};

	const errorModalDetails = {
		cancelBtnLabel: t('common:cancel'),
		confirmBtnLabel: t('common:contactSupport'),
		description: t('pages:broadcast.broadcastData.errorModalDescription'),
		heading: t('common:contactSupport'),
	};

	const showSandboxButton = () =>
		!configuredChannels.includes(CHANNELS.WHATSAPP) &&
		hasDialog360Provider &&
		isSandboxEnabled &&
		!marketingCampaignsAnalyticsType &&
		!isWhatsappSandboxConfigured;

	const broadcastHelperProps: IBroadcastHelpers = {
		isContactSupportModalOpen: broadcastHelpers.isContactSupportModalOpen,
		isRetryModalOpen: broadcastHelpers.isRetryModalOpen,
		isRetryModalLoading: broadcastHelpers.isRetryModalLoading,
		retryBroadcastData: broadcastHelpers.retryBroadcastData,
		setRetryModal: broadcastHelpers.setRetryModal,
		setContactSupportModal: broadcastHelpers.setContactSupportModal,
		retryFailedUsers: broadcastHelpers.retryFailedUsers,
	};

	const { size, page, totalPages, totalElements } = broadcastPagination;
	const [contactSales, setContactSales] = useState<boolean>(false);

	useEffect(() => {
		getDefaultColumns();
		onPaginationChange();
	}, []);

	useEffect(() => {
		broadcastHelpers.getWhatsappConfigration(setHasDialog360Provider);
	}, []);

	useEffect(() => {
		setIdColorMap(assignColorsForSplitBroadcast(data));
	}, [data]);

	useEffect(() => {
		setPagination({
			size,
			page,
			totalPages,
			totalElements,
		});
	}, [broadcastPagination]);

	useEffect(() => {
		if (isWhatsappAcceleratorTrial) {
			dispatch(updateFirstBroadcastJourneyData());
		}
	}, [journeyPhaseDetails]);

	useEffect(() => {
		checked.length > 0 &&
			setTotalUsersTargetted(
				checked.reduce((accumulator, segment) => accumulator + (segmentUserCount[segment] || 0), 0)
			);
	}, [checked]);

	useEffect(() => {
		const buttons: Array<string> = [];
		const modifyingResponse = (response: Array<IObjProps>) => {
			response.map((obj) => {
				if (obj.value === PREDEFINED_SEGMENTS.CLICKED) {
					obj.children.forEach((button: IObjProps) => {
						buttons.push(button.value);
					});
					setValues(buttons);
				}
				userCount[obj.value] = obj?.userCount;
				if (obj?.children?.length > 0) {
					modifyingResponse(obj.children);
				}
			});
		};
		const userCount: IObjProps = {};
		segmentNodes.length && modifyingResponse(segmentNodes);
		const modifiedValues: Array<string> = buttons.length ? [] : [PREDEFINED_SEGMENTS.CLICKED];
		setChecked([]);
		setSegmentUserCount(userCount);
	}, [segmentNodes]);

	const tableOverrides = {
		wrapper: {
			marginLeft: '0rem',
			marginRight: '0rem',
			marginTop: '0rem',
			flexGrow: 1,
			boxSizing: 'border-box',
			overflow: 'hidden',
		},
		Block: {
			paddingTop: marketingCampaignsAnalyticsType ? '1rem' : '',
		},
	};

	enum BROADCAST_COLUMNS {
		AUDIENCE_COUNT = 'audienceCount',
		BROADCAST_TITLE = 'broadcastTitle',
		BUTTONS_CLICKED = 'buttonsClicked',
		CTA_COUNT = 'ctaCount',
		DELIVERED_COUNT = 'deliveredCount',
		ESTIMATED_COMPLETION_TIME = 'estimatedCompletionTime',
		FAILED_COUNT = 'failedCount',
		PLATFORMS = 'platforms',
		PUBLISHED_ON = 'publishedOn',
		READ_COUNT = 'readCount',
		SEGMENT_RESPONSE = 'segmentResponse',
		STATUS = 'status',
		SENT_COUNT = 'sentCount',
		TARGET_USER_COUNT = 'targetUserCount',
		UNSENT = 'unsent',
		CREATED_BY = 'createdBy',
	}

	enum SortOptionValues {
		NAME_ASC = 'name,asc',
		TITLE_ASC = 'broadCastTitle,asc',
		PUBLISH_DESC_V1 = 'publishedOn,desc',
		PUBLISH_DESC_V2 = 'publishOn,desc',
		PUBLISH_ASC_V1 = 'publishedOn,asc',
		PUBLISH_ASC_V2 = 'publishOn,asc',
		USER_COUNT_ASC = 'targetUserCount,asc',
		USER_COUNT_DESC = 'targetUserCount,desc',
	}

	const sortOptions = [
		{
			name: t('components:broadcastSort.broadcastAsc'),
			value: isBroadcastV2Enabled ? SortOptionValues.NAME_ASC : SortOptionValues.TITLE_ASC,
		},
		{
			name: t('components:broadcastSort.newestFirst'),
			value: isBroadcastV2Enabled ? SortOptionValues.PUBLISH_DESC_V2 : SortOptionValues.PUBLISH_DESC_V1,
		},
		{
			name: t('components:broadcastSort.oldestFirst'),
			value: isBroadcastV2Enabled ? SortOptionValues.PUBLISH_ASC_V2 : SortOptionValues.PUBLISH_ASC_V1,
		},
		{
			name: t('components:broadcastSort.maxUserFirst'),
			value: SortOptionValues.USER_COUNT_DESC,
		},
		{
			name: t('components:broadcastSort.minUserFirst'),
			value: SortOptionValues.USER_COUNT_ASC,
		},
	];

	const onSortingBroadcast = (data: any) => {
		dispatch(setBroadcastDataSortAction(data));

		const { filters }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());

		const { startDate } = filters;
		const { endDate } = filters;
		const channels = getChannel(filters.channels, configuredChannels, isBroadcastV2Enabled);

		const filterData = {
			filters: {
				...filters,
			},
			sort: data,
		};
		dispatch(broadcastFilterActions.setFiltersToLS(filterData));

		const params: IObjProps = {
			targetApi: isBroadcastV2Enabled ? 'broadcastHistoryV2' : 'broadcastHistory',
			status: BROADCAST_STATUS.BATCHING,
			page: (page || 1) - 1,
			size,
			sort: data,
			startDate,
			endDate,
			channels,
		};

		dispatch(getPublishedBroadcastAction(params, data));
	};

	const onPaginationChange = (modifiedPage = page, modifiedSize = paginationSize || size) => {
		const { filters }: IObjProps = dispatch(broadcastFilterActions.getFiltersFromLs());

		const { startDate } = filters;
		const { endDate } = filters;
		const channels = getChannel(filters.channels, configuredChannels, isBroadcastV2Enabled);

		const params: IObjProps = {
			targetApi: isBroadcastV2Enabled ? 'broadcastHistoryV2' : 'broadcastHistory',
			status: BROADCAST_STATUS.BATCHING,
			page: (modifiedPage || 1) - 1,
			size: modifiedSize,
			sort: sort || (isBroadcastV2Enabled ? SortOptionValues.PUBLISH_DESC_V2 : SortOptionValues.PUBLISH_DESC_V1),
			startDate,
			endDate,
			channels,
		};

		dispatch(getPublishedBroadcastAction(params, params.sort));
	};

	const optOutHoverText = (totalUserCount: number, optOutUserCount: number) => (
		<>
			{t('pages:broadcast.basic.optOutUserTooltip1', { totalUserCount })}
			<br />
			{t('pages:broadcast.basic.optOutUserTooltip2', { optOutCount: optOutUserCount })}
		</>
	);

	const paginationMethods = usePaginationHook({
		onChange: onPaginationChange,
	});

	const { pagination, setPagination } = paginationMethods;

	const { size: paginationSize } = pagination;

	const expiryOfLink = (data: any) => {
		const myDate = new Date(data.createdOn);
		myDate.setDate(myDate.getDate() + 7);

		return new Date() <= myDate;
	};

	const renderPublishedRowActions = () => [
		{
			onClick: (data: any) => {
				let actions: Array<IObjProps> = [];
				data.isExternal
					? (actions = [publishedRowActions.refresh])
					: (actions = [
							publishedRowActions.view,
							publishedRowActions.copy,
							publishedRowActions.export,
							publishedRowActions.stop,
							publishedRowActions.resume,
							publishedRowActions.retry,
							publishedRowActions.contactSupport,
							publishedRowActions.retarget,
							publishedRowActions.autoRetry,
						]);
				setAdminActions(actions);
			},
			items: adminActions,

			icon: <Overflow size={24} />,
			visible: (data: any) => !(data['isExternal'] && data.status === BROADCAST_STATUS.SENT),
		},
	];

	useEffect(() => {
		const getSpanSizeForStatus = (generalSpan: any) =>
			defaultColumns[BROADCAST_COLUMNS.READ_COUNT] && defaultColumns[BROADCAST_COLUMNS.SENT_COUNT]
				? generalSpan + 0.3
				: defaultColumns[BROADCAST_COLUMNS.READ_COUNT] || defaultColumns[BROADCAST_COLUMNS.SENT_COUNT]
					? generalSpan + 0.15
					: generalSpan;
		const updatedColumns = [];
		let defaultColumnsCount = 9;
		if (Object.keys(defaultColumns).length > 0) {
			defaultColumnsCount = Object.keys(defaultColumns).length;
		}
		const generalSpan = (defaultColumnsCount <= 6 ? 10.5 : 11.5) / defaultColumnsCount;
		for (const [key, value] of Object.entries(defaultColumns)) {
			switch (key) {
				case BROADCAST_COLUMNS.BROADCAST_TITLE: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.broadcastName'),
						processor: (data: any, rowData: any) =>
							rowData.isExternal ? (
								<>
									{data}
									<span
										className={css({
											fontSize: '0.7rem',
											marginLeft: '0.3rem',
										})}
									>
										{t('pages:broadcast.draft.external')}
									</span>
								</>
							) : data ? (
								<>
									{data && (
										<span
											className={css({
												textDecoration: 'underline',
												cursor: 'pointer',
											})}
											onClick={() => {
												rowData.targetUserCount
													? navigate(
															`${`${ThreeColumnRouteLinks.broadcastAnalyticsSelected}?id=`}${encodeURIComponent(
																rowData.broadcastId
															)}`
														)
													: setViewNoUsersModal(true);
											}}
										>
											{data}
										</span>
									)}
									{rowData.splitBroadcast && (
										<ENGTTooltip
											content={splitBroadcastHoverText(data, t)}
											ignoreBoundary={false}
											placement={PLACEMENT.rightTop}
										>
											<span
												className={css({
													marginLeft: '0.3rem',
												})}
											>
												<LinkedSplitBroadcastIcon fill={idColorMap[rowData.parentSplitId]} />
											</span>
										</ENGTTooltip>
									)}
								</>
							) : (
								'-'
							),
					});
					break;
				}
				case BROADCAST_COLUMNS.PUBLISHED_ON: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.publishedTime'),
						processor: (data: any) =>
							data
								? t('common:standardDateTimeFormatter', {
										date: data,
									})
								: '-',
					});
					break;
				}
				case BROADCAST_COLUMNS.SEGMENT_RESPONSE: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.segment'),
						processor: (data: any, rowData: any) => {
							if (rowData.targetGroup === 'CONTACT_LIST') {
								return (
									<NavLink
										to={usersContactsListLink}
										className={css({
											textDecoration: 'underline',
											color: theme.colors.primaryA,
										})}
									>
										{rowData.contactMetadataName}
									</NavLink>
								);
							}
							if (rowData.targetGroup === segmentOptions.RETARGET) {
								return rowData.retargetSegment.join(', ');
							}
							if (data) {
								const filter = '?filter=frombroadcast';
								const query = `query=${data.solrQuery}`;

								return (
									<NavLink
										to={`${usersListLink}${filter}&${query}`}
										className={css({
											textDecoration: 'underline',
											color: theme.colors.primaryA,
										})}
									>
										{data.segmentName}
									</NavLink>
								);
							}

							return (
								<NavLink
									to={usersListLink}
									className={css({
										textDecoration: 'underline',
										color: theme.colors.primaryA,
									})}
								>
									{t('pages:broadcast.basic.allUsers')}
								</NavLink>
							);
						},
					});
					break;
				}
				case BROADCAST_COLUMNS.PLATFORMS: {
					updatedColumns.push({
						id: key,
						name: t('pages:broadcast.draft.channels'),
						span: generalSpan,
						processor: (data: any) => {
							const modifiedData = modifyChannels(data, isBroadcastV2Enabled);

							return modifiedData.map((channel: string) => getChannelTooltip(channel, css));
						},
					});
					break;
				}
				case BROADCAST_COLUMNS.TARGET_USER_COUNT: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.targetUsers'),
						tooltip: t('pages:broadcast.broadcastData.targetUserCountTooltip'),
						processor: (data: any, rowData: any) => (
							<>
								{data === null ? (
									'-'
								) : rowData.isExternal && expiryOfLink(rowData) ? (
									<span
										className={css({
											display: 'inline-flex',
											gap: '0.375rem',
										})}
									>
										{data.toLocaleString()}
										<ENGTTooltip
											content={t('pages:broadcast.basic.targetUserDownloadTooltip')}
											ignoreBoundary={false}
											placement={PLACEMENT.rightTop}
										>
											<span
												className='broadcast-download-btn'
												onClick={() =>
													broadcastHelpers.downloadUsersForExternalBroadcast(rowData)
												}
											>
												<DownloadIcon fillColor={theme.colors.primaryA} />
											</span>
										</ENGTTooltip>
									</span>
								) : data > 0 &&
								  rowData.publishedOn >= BLOCK_BROADCAST_USER_DOWNLOAD_BEFORE &&
								  rowData.status !== BROADCAST_STATUS.BATCHING &&
								  (rowData.status !== BROADCAST_STATUS.IN_PROGRESS ||
										(rowData.status === BROADCAST_STATUS.IN_PROGRESS &&
											broadcastHelpers.isETAAfterTwoHours(rowData.estimatedCompletionTime))) &&
								  !rowData.isExternal &&
								  !rowData?.optOutUserCount ? (
									<span
										className={css({
											display: 'inline-flex',
											gap: '0.375rem',
										})}
									>
										{data.toLocaleString()}
										<ENGTTooltip
											content={t('pages:broadcast.basic.targetUserDownloadTooltip')}
											ignoreBoundary={false}
											placement={PLACEMENT.rightTop}
										>
											<span
												className='broadcast-download-btn'
												onClick={() => broadcastHelpers.downloadTargetUsers(rowData)}
											>
												<DownloadIcon fillColor={theme.colors.primaryA} />
											</span>
										</ENGTTooltip>
									</span>
								) : data > 0 ? (
									<span>{data.toLocaleString()}</span>
								) : rowData?.status === BROADCAST_STATUS.PREPARING ? (
									<ENGTTooltip
										content={t('pages:broadcast.basic.preparingBroadcastTooltip')}
										ignoreBoundary={false}
										placement={PLACEMENT.rightTop}
									>
										-
									</ENGTTooltip>
								) : (
									'0'
								)}
								{rowData?.optOutUserCount > 0 && (
									<span
										className={css({
											fontWeight: 400,
											marginLeft: '0.2rem',
											display: 'inline-flex',
											gap: '0.375rem',
										})}
									>
										<ENGTTooltip
											content={optOutHoverText(data.toLocaleString(), rowData.optOutUserCount)}
											ignoreBoundary={false}
											placement={PLACEMENT.rightTop}
										>
											<span style={{ opacity: 0.5 }}>{` | ${rowData.optOutUserCount}`}</span>
										</ENGTTooltip>
										<ENGTTooltip
											content={t('pages:broadcast.basic.targetUserDownloadTooltip')}
											ignoreBoundary={false}
											placement={PLACEMENT.rightTop}
										>
											<span
												className='broadcast-download-btn'
												onClick={() => broadcastHelpers.downloadTargetUsers(rowData)}
											>
												<DownloadIcon fillColor={theme.colors.primaryA} />
											</span>
										</ENGTTooltip>
									</span>
								)}
							</>
						),
					});
					break;
				}
				case BROADCAST_COLUMNS.FAILED_COUNT: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.failed'),
						tooltip: t('pages:broadcast.broadcastData.failedUserCountTooltip'),
						processor: (data: any, rowData: any) => (
							<>
								{data === null ? (
									<ENGTTooltip
										content={t('pages:broadcast.basic.failedUserCaptureTooltip')}
										ignoreBoundary={false}
										placement={PLACEMENT.rightTop}
									>
										-
									</ENGTTooltip>
								) : data > 0 &&
								  rowData.publishedOn >= BLOCK_BROADCAST_USER_DOWNLOAD_BEFORE &&
								  (rowData.status !== BROADCAST_STATUS.IN_PROGRESS ||
										(rowData.status === BROADCAST_STATUS.IN_PROGRESS &&
											broadcastHelpers.isETAAfterTwoHours(rowData.estimatedCompletionTime))) ? (
									<span
										className={css({
											display: 'inline-flex',
											gap: '0.375rem',
										})}
									>
										{data.toLocaleString()}
										<ENGTTooltip
											content={t('pages:broadcast.basic.failedUserDownloadTooltip')}
											ignoreBoundary={false}
											placement={PLACEMENT.rightTop}
										>
											<span
												className='broadcast-download-btn'
												onClick={() => broadcastHelpers.downloadFailedUsers(rowData)}
											>
												<DownloadIcon fillColor={theme.colors.primaryA} />
											</span>
										</ENGTTooltip>
									</span>
								) : data > 0 ? (
									<span>{data.toLocaleString()}</span>
								) : (
									<ENGTTooltip
										content={t('pages:broadcast.basic.failedUserCaptureTooltip')}
										ignoreBoundary={false}
										placement={PLACEMENT.rightTop}
									>
										-
									</ENGTTooltip>
								)}
							</>
						),
					});
					break;
				}
				case BROADCAST_COLUMNS.STATUS: {
					updatedColumns.push({
						id: key,
						span: getSpanSizeForStatus(generalSpan),
						name: t('pages:broadcast.draft.status'),
						processor: (data: any, rowData: any) => {
							if (
								rowData.broadcastRetryType === BROADCAST_RETRY_TYPE.AUTOMATIC &&
								rowData.automaticRetryBroadcastMetadataDTO?.status &&
								rowData.automaticRetryBroadcastMetadataDTO.hoverMessageMetadataDTO?.hoverMessageKey &&
								rowData.automaticRetryBroadcastMetadataDTO.status !== BROADCAST_STATUS.COMPLETED
							) {
								const broadcastMetadata = rowData.automaticRetryBroadcastMetadataDTO;
								const hoverMessageMetadata = broadcastMetadata.hoverMessageMetadataDTO;
								let toolTipMsg = '';

								switch (hoverMessageMetadata.hoverMessageKey) {
									case AutoRetryHoverMessageKey.AUTO_RETRY_COMPLETE:
										toolTipMsg = t(
											'pages:broadcast.basic.autoRetryHoverMessage.autoRetryCompleteKey'
										);
										break;

									case AutoRetryHoverMessageKey.SUBSEQUENT_AUTO_RETRY_SCHEDULED:
										toolTipMsg = t(
											'pages:broadcast.basic.autoRetryHoverMessage.subsequentAutoRetryScheduledKey',
											{
												retriesDone: hoverMessageMetadata.retriesDone,
												totalRetries: hoverMessageMetadata.totalRetries,
												currentRetry: hoverMessageMetadata.currentRetry,
												nextRetryInHours: hoverMessageMetadata.nextRetryInHours,
											}
										);
										break;

									case AutoRetryHoverMessageKey.FIRST_AUTO_RETRY_SCHEDULED:
										toolTipMsg = t(
											'pages:broadcast.basic.autoRetryHoverMessage.firstAutoRetryScheduledKey',
											{ nextRetryInHours: hoverMessageMetadata.nextRetryInHours }
										);
										break;

									case AutoRetryHoverMessageKey.AUTO_RETRY_IN_PROGRESS:
										toolTipMsg = t(
											'pages:broadcast.basic.autoRetryHoverMessage.autoRetryInProgressKey',
											{
												retriesDone: hoverMessageMetadata.retriesDone,
												totalRetries: hoverMessageMetadata.totalRetries,
												currentRetry: hoverMessageMetadata.currentRetry,
											}
										);
										break;
								}

								return (
									<AutoRetryStatusIcon
										fillColorForText={theme.colors.primaryA}
										fillColorForImage={theme.retryingIcon}
										statusMessage={broadcastMetadata.status}
										toolTipMsg={toolTipMsg}
									/>
								);
							}
							if (data === BROADCAST_STATUS.IN_PROGRESS || data === BROADCAST_STATUS.BATCHING) {
								return (
									<InProgressIcon
										fillColorForText={theme.colors.primaryA}
										fillColorForImage={theme.inProgressIcon}
									/>
								);
							}
							if (data === BROADCAST_STATUS.RETRYING) {
								return (
									<RetryingIcon
										fillColorForText={theme.colors.primaryA}
										fillColorForImage={theme.retryingIcon}
									/>
								);
							}
							if (data === BROADCAST_STATUS.PREPARING) {
								return (
									<PreparingIcon
										fillColorForText={theme.colors.primaryA}
										fillColorForImage={theme.preparingIcon}
									/>
								);
							}
							if (data === BROADCAST_STATUS.SENT) {
								return (
									<CompletedIcon
										fillColorForText={theme.colors.primaryA}
										fillColorForImage={theme.completedIcon}
									/>
								);
							}
							if (data === BROADCAST_STATUS.STOPPING) {
								return (
									<StoppingIcon
										fillColorForText={theme.colors.primaryA}
										fillColorForImage={theme.stoppedIcon}
									/>
								);
							}
							if (data === BROADCAST_STATUS.AUTO_RETRY_STOPPED) {
								return (
									<RetryStoppedIcon
										fillColorForText={theme.colors.primaryA}
										fillColorForImage={theme.stoppedIcon}
										toolTipMsg={t('components:stopRetryBroadcastTooltip')}
									/>
								);
							}
							if (data === BROADCAST_STATUS.STOPPED) {
								return (
									<StoppedIcon
										fillColorForText={theme.colors.primaryA}
										fillColorForImage={theme.stoppedIcon}
										toolTipMsg={
											rowData.reasonToStop === null || rowData.reasonToStop === 0
												? t('components:stopBroadcastToaster.tooltip')
												: rowData.reasonToStop
										}
									/>
								);
							}
							if (data === BROADCAST_STATUS.SUBMITTED) {
								const currentDate = new Date().toISOString();

								return (
									<>
										{rowData.estimatedCompletionTime < currentDate ? (
											<PendingOnWhatsAppIcon
												fillColorForText={theme.colors.primaryA}
												fillColorForImage={theme.stoppedIcon}
											/>
										) : (
											<SendingOnWhatsAppIcon
												fillColorForText={theme.colors.primaryA}
												fillColorForImage={theme.sentToWhatsApp}
											/>
										)}
									</>
								);
							}

							return (
								<FailedIcon
									fillColorForText={theme.colors.primaryA}
									fillColorForImage={theme.failure}
								/>
							);
						},
					});
					break;
				}
				case BROADCAST_COLUMNS.SENT_COUNT: {
					updatedColumns.push({
						id: key,
						span: generalSpan - 0.15,
						name: t('pages:broadcast.broadcastData.sent'),
						tooltip: t('pages:broadcast.broadcastData.sentTooltip'),
						processor: (data: any) =>
							data || (
								<ENGTTooltip
									content={t('pages:broadcast.basic.sentUserCaptureTooltip')}
									ignoreBoundary={false}
									placement={PLACEMENT.rightTop}
								>
									-
								</ENGTTooltip>
							),
					});
					break;
				}
				case BROADCAST_COLUMNS.DELIVERED_COUNT: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.delivered'),
						tooltip: t('pages:broadcast.broadcastData.deliveredTooltip'),
						processor: (data: any) => data || '-',
					});
					break;
				}
				case BROADCAST_COLUMNS.READ_COUNT: {
					updatedColumns.push({
						id: key,
						span: generalSpan - 0.15,
						name: t('pages:broadcast.broadcastData.read'),
						tooltip: t('pages:broadcast.broadcastData.readTooltip'),
						processor: (data: any) => data || '-',
					});
					break;
				}
				case BROADCAST_COLUMNS.CREATED_BY: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.createdBy'),
						tooltip: t('pages:broadcast.broadcastData.createdByTooltip'),
						processor: (data: any, row: any) => (
							<span
								className={css({
									marginLeft: '1rem',
									width: 'calc( 100% - 3rem )',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								})}
							>
								<ENGTTooltip
									usage='list'
									content={
										<>
											<div style={{ opacity: 0.6 }}>{data || t('common:notAvailable')}</div>
											<div>
												{row?.creatorEmailId ? row.creatorEmailId : t('common:notAvailable')}
											</div>
										</>
									}
									ignoreBoundary={false}
								>
									<div
										className={css({
											width: '100%',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											fontSize: '.8rem',
											opacity: data ? 1 : 0.5,
										})}
									>
										{data || t('common:notAvailable')}
									</div>
								</ENGTTooltip>
							</span>
						),
					});
					break;
				}
				case BROADCAST_COLUMNS.ESTIMATED_COMPLETION_TIME: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: value,
						processor: (data: any) =>
							data
								? t('common:standardDateTimeFormatter', {
										date: data,
									})
								: '-',
					});
					break;
				}
				case BROADCAST_COLUMNS.UNSENT: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.unsent'),
						tooltip: t('pages:broadcast.broadcastData.unsentTooltip'),
						processor: (data: any) => data || '-',
					});
					break;
				}
				case BROADCAST_COLUMNS.AUDIENCE_COUNT: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: value,
						tooltip: t('pages:broadcast.broadcastData.submittedToolTip'),
						processor: (data: any) => data || '-',
					});
					break;
				}
				case BROADCAST_COLUMNS.CTA_COUNT: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.ctaUrlClick'),
						tooltip: t('pages:broadcast.broadcastData.ctaUrlClickTooltip'),
						processor: (data: any) => data || '-',
					});
					break;
				}
				case BROADCAST_COLUMNS.BUTTONS_CLICKED: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: t('pages:broadcast.broadcastData.clickRate'),
						tooltip: t('pages:broadcast.broadcastData.clickThroughRateTooltip'),
						processor: (data: any, row: any) =>
							row.sentCount
								? `${parseFloat(((row.buttonsClicked / row.sentCount) * 100).toFixed(2))}%`
								: '-',
					});
					break;
				}
				default: {
					updatedColumns.push({
						id: key,
						span: generalSpan,
						name: value,
						processor: (data: any) =>
							data ? (
								<div
									className={css({
										wordBreak: 'break-word',
									})}
								>
									{data}
								</div>
							) : (
								'-'
							),
					});
				}
			}
		}
		setBroadcastColumns(updatedColumns);
	}, [defaultColumns]);

	const publishedRowActions = {
		view: {
			label: t('pages:broadcast.actions.viewBroadcast'),
			onClick: (data: any) =>
				data.targetUserCount
					? navigate(`${`/broadcast/published/view` + '?id='}${encodeURIComponent(data.broadcastId)}`)
					: setViewNoUsersModal(true),
		},
		retry: {
			label: t('pages:broadcast.actions.retryBroadcast'),
			onClick: (data: any) => {
				const { disabled = false, status = '' } = data;
				setSelectedBroadcastId(data.broadcastId);
				broadcastHelpers.getRetryBroadcast(data.broadcastId);
				if (disabled && status === BROADCAST_STATUS.SENT) {
					setRetryBroadcastModal(false);
					toaster.negative(
						<ENGTToasterContainer
							title={t('pages:broadcast.retryError.heading')}
							description={t('pages:broadcast.retryError.description')}
						/>,
						{}
					);
				}
			},
			visible: (data: any) =>
				!timeIntervalExceedsFiveDays(data.publishedOn) &&
				data?.failedCount > 0 &&
				data.publishedOn >= BLOCK_BROADCAST_USER_DOWNLOAD_BEFORE &&
				isWhatsappConfigured &&
				(data.status === BROADCAST_STATUS.SENT ||
					data.status === BROADCAST_STATUS.FAILED ||
					(data.status === BROADCAST_STATUS.AUTO_RETRY_STOPPED &&
						data.broadcastRetryType === BROADCAST_RETRY_TYPE.MANUAL)) &&
				!(data.broadcastRetryType === BROADCAST_RETRY_TYPE.AUTOMATIC),
		},

		autoRetry: {
			label: t('pages:broadcast.actions.stopAutoRetry'),
			onClick: (data: any) => {
				broadcastHelpers.stopAutoRetryBroadcast(data.broadcastId);
			},
			visible: (data: any) =>
				data.status === BROADCAST_STATUS.SENT && data.broadcastRetryType === BROADCAST_RETRY_TYPE.AUTOMATIC,
		},
		copy: {
			label: t('pages:broadcast.actions.copyBroadcast'),
			onClick: (data: any) => {
				const {
					broadcastId,
					retargetBroadcast = false,
					retargetedBroadcastId = '',
					platforms = [],
					retargetSegment = [],
					disabled = false,
					status = '',
					broadcastObjective,
					shopifySegmentId,
				} = data;
				setSelectedBroadcastData(data);
				retargetBroadcast &&
					broadcastHelpers.getTargetUserCount(retargetedBroadcastId, platforms, retargetSegment);
				const segmentDetails = {
					id: retargetSegment?.join(', '),
					label: retargetSegment?.join(', '),
					content: {},
				};
				const queryData = {
					retargetSegments: segmentDetails,
					retargetUsers: broadcastHelpers.retargetSegmentUsers,
					id: broadcastId,
					isRetarget: !!selectedBroadcastData?.retargetBroadcast,
					status: accountStatus,
					platforms,
					shopifySegmentId,
				};
				if (platforms.includes(PLATFORM.WHATSAPP) && !isWhatsappConfigured) {
					setWhatsappNotConfigured(true);
				} else if (disabled && status === BROADCAST_STATUS.SENT) {
					toaster.negative(
						<ENGTToasterContainer
							title={t('pages:broadcast.copyError.heading')}
							description={t('pages:broadcast.copyError.description')}
						/>,
						{}
					);
				} else {
					const queryString = `mode=${BROADCAST_TYPE.COPY}&payload=${encodeURIComponent(
						JSON.stringify(queryData)
					)}&path=published`;
					navigate('/broadcast/create?' + `${queryString}`);
				}
			},
		},
		export: {
			label: t('pages:broadcast.actions.exportBroadcast'),
			onClick: (data: any) => {
				broadcastHelpers.exportData(data);
			},
			visible: (data: any) => data.status !== BROADCAST_STATUS.IN_PROGRESS && data.targetUserCount > 0,
		},
		stop: {
			label: t('pages:broadcast.actions.stopBroadcast'),
			visible: (data: any) => data.status === BROADCAST_STATUS.IN_PROGRESS,
			onClick: (data: any) => {
				setSelectedBroadcastData(data);
				toggleStopModal(true);
			},
		},
		resume: {
			label: t('pages:broadcast.actions.resumeBroadcast'),
			visible: (data: any) =>
				!timeIntervalExceedsFiveDays(data.publishedOn) &&
				data.status === BROADCAST_STATUS.STOPPED &&
				isBroadcastV2Enabled,
			onClick: (data: any) => {
				const {
					broadcastId,
					retargetBroadcast = false,
					retargetedBroadcastId = '',
					platforms = [],
					retargetSegment = [],
					disabled = false,
					status = '',
					pendingUserCount,
				} = data;
				setSelectedBroadcastData(data);
				retargetBroadcast &&
					broadcastHelpers.getTargetUserCount(retargetedBroadcastId, platforms, retargetSegment);
				const segmentDetails = {
					id: retargetSegment?.join(', '),
					label: retargetSegment?.join(', '),
					content: {},
				};
				const queryData = {
					retargetSegments: segmentDetails,
					retargetUsers: broadcastHelpers.retargetSegmentUsers,
					id: broadcastId,
					isRetarget: !!selectedBroadcastData?.retargetBroadcast,
					status: accountStatus,
					platforms,
					pendingUserCount,
				};
				if (platforms.includes(PLATFORM.WHATSAPP) && !isWhatsappConfigured) {
					setWhatsappNotConfigured(true);
				} else if (disabled && status === BROADCAST_STATUS.SENT) {
					toaster.negative(
						<ENGTToasterContainer
							title={t('pages:broadcast.copyError.headingResumed')}
							description={t('pages:broadcast.copyError.description')}
						/>,
						{}
					);
				} else {
					const queryString = `mode=${BROADCAST_TYPE.RESUME}&payload=${encodeURIComponent(
						JSON.stringify(queryData)
					)}&path=published`;
					navigate('/broadcast/create?' + `${queryString}`);
				}
			},
		},
		refresh: {
			label: t('pages:broadcast.actions.refreshStatus'),
			visible: (data: any) => data.status !== BROADCAST_STATUS.SENT,
			onClick: (data: any) => {
				const params = {
					broadcast_uuid: data.broadcastId,
					targetApi: 'getExternalBroadcastStatus',
				};
				refreshBroadcast(
					() =>
						dispatch(
							modifyBroadcastDataAction(data.broadcastId, { ...data, status: BROADCAST_STATUS.SENT })
						),
					params
				);
			},
		},
		contactSupport: {
			label: t('pages:broadcast.actions.contactSupport'),
			visible: (data: any) =>
				data.status === BROADCAST_STATUS.SUBMITTED && timeIntervalExceedsThreeHours(data.publishedOn),
			onClick: (data: any) => {
				setContactSales(true);
			},
		},
		retarget: {
			label: t('pages:broadcast.broadcastData.retargetBroadcast'),
			visible: (data: any) =>
				isWhatsappConfigured &&
				(provider === WHATSAPP_PROVIDERS.dialog360 || provider === WHATSAPP_PROVIDERS.whatsAppCloud) &&
				data?.isTemplateMessage &&
				data?.targetUserCount &&
				data?.status !== BROADCAST_STATUS.IN_PROGRESS &&
				data?.status !== BROADCAST_STATUS.RETRYING &&
				data?.status !== BROADCAST_STATUS.STOPPING &&
				data?.status !== BROADCAST_STATUS.FAILED,
			disable: (data: any) => timeIntervalExceedsSevenDays(data.publishedOn),
			showTooltip: t('pages:broadcast.broadcastData.retargetBroadcastTooltip'),
			onClick: (data: any) => {
				if (data.sentCount < 1) {
					setNoDataForRetarget(true);

					return;
				}
				callRetargetApi(data);
			},
		},
	};

	const child: JSX.Element | undefined = isEngatiBrand ? (
		<span
			className={css({
				color: theme.colors.primaryA,
			})}
		>
			<a
				style={{ color: theme.colors.accent }}
				href={DOC_LINKS_V2.broadcast.basic}
				target='_blank'
				rel='noreferrer'
			>
				{t('pages:broadcast.basic.clickHere')}
			</a>
			{t('pages:broadcast.basic.learnBroadcast')}
		</span>
	) : (
		<></>
	);

	const createSandbox = (isFooterButton: boolean) =>
		showSandboxButton() ? (
			<ENGTButton
				onClick={() => {
					writeToLs(LOCAL_STORAGE_VALUES.SET_DIALOG360_ENABLED, 'true');
					writeToLs(LOCAL_STORAGE_VALUES.SET_SANDBOX_ENABLED, 'true');
					navigate(ThreeColumnRouteLinks.configureDepWhatsappLink);
				}}
				label={t('pages:landing.overview.accountNotConnected.connectToSandbox')}
				size={SIZE.compact}
				kind={KIND.primary}
				className={css({
					display: broadcastData.data.length || isFooterButton ? '' : 'none  ! important',
					marginBottom: isFooterButton ? '0rem' : '2rem !important',
					marginTop: isFooterButton ? '3rem' : '0rem',
				})}
			/>
		) : (
			<></>
		);

	const createNoDataTemplateFooter = () => (
		<div className={css({ display: 'flex', gap: '1rem', justifyContent: 'center' })}>
			{createSandbox(true)}
			<ENGTButton
				size={SIZE.compact}
				kind={KIND.primary}
				label={t('pages:broadcast.basic.createNewBroadCast')}
				startEnhancer={<AddIcon />}
				className={css({
					marginTop: '3rem',
				})}
				onClick={() => {
					fetchAccountStatus();
					sendButtonClickEventToGA4({ gtmTrackingId: GTMTrackingIds.CREATE_NEW_BROADCAST_BUTTON });
				}}
			/>
		</div>
	);

	const onConfirmStop = () => {
		const params = {
			replaceable_params: {
				broadcastId: selectedBroadcastData.broadcastId,
			},
			targetApi: isBroadcastV2Enabled ? 'stopBroadcastForV2' : 'stopBroadcast',
		};
		stopBroadcast(params, (isStopped: boolean) => {
			toggleStopModal(false);
			dispatch(
				modifyBroadcastDataAction(selectedBroadcastData.broadcastId, {
					...selectedBroadcastData,
					status: BROADCAST_STATUS.STOPPING,
				})
			);
		});
	};

	const renderSortAndMenu = () =>
		data.length > 0 ? (
			<Block
				display='flex'
				gridGap={['1rem', '1rem', '1rem', '.5rem']}
				justifyContent='center'
				alignItems='center'
				className={css({
					float: 'right',
				})}
			>
				<BroadcastSort initialValue={sort} sortOptions={sortOptions} onSortValueChange={onSortingBroadcast} />
				<BroadcastMenu
					columns={columns}
					defaultColumns={defaultColumns}
					setDefaultColumns={setDefaultColumns}
				/>
			</Block>
		) : (
			<></>
		);

	const broadcastHeader = () => (
		<>
			<Block
				display='flex'
				justifyContent={['flex-end', 'flex-end', 'space-between']}
				alignItems='flex-end'
				flexWrap
				gridGap={['0rem', '0rem', '0rem', '2rem']}
			>
				{!marketingCampaignsAnalyticsType && (
					<ENGTHeader>
						<PageHeader heading={t('pages:broadcast.broadcastHistory.broadcastHistory')} />
					</ENGTHeader>
				)}
				<Block display='flex' gridGap='1rem' flexWrap>
					{createSandbox(false)}
					{!marketingCampaignsAnalyticsType && (
						<CreateBroadcastBtn
							onClick={fetchAccountStatus}
							overrides={{
								display: broadcastData.data.length ? '' : 'none',
							}}
						/>
					)}
					{isSmallDesktopScreen && (
						<>
							<BroadcastFilter />
							{renderSortAndMenu()}
						</>
					)}
				</Block>
			</Block>
			{!marketingCampaignsAnalyticsType && isWhatsappConfigured && (
				<PageSubHeading
					subHeading={renderWhatsappTemplateNote(isEngatiBrand, t)}
					cssOverrides={{
						marginBottom: '1rem',
						width: isSmallDesktopScreen ? '100%' : '90%',
					}}
				/>
			)}
		</>
	);

	const allIncludedChecker = (source: Array<string>, target: Array<string>) =>
		target.every((v) => source.includes(v));

	const allEqualChecker = (source: Array<string>, target: Array<string>) =>
		source.length === target.length && source.every((element, index) => element === target[index]);

	const modifySegmentName = (checkedBoxes: Array<string>) => {
		const modifiedSegmentName = Object.values(treeHierarchy)
			.reduce((result, values) => {
				treeHierarchy[Object.keys(treeHierarchy).length + 1] = values.map((value: any) => value.toUpperCase());

				const tempName = checkedBoxes
					.filter((option) => treeHierarchy[Object.keys(treeHierarchy).length].includes(option.toUpperCase()))
					.map((option) =>
						option
							.replace(/\w+/g, (word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
							.replaceAll(' ', '')
					)
					.join(', ');

				if (tempName) {
					result.push(tempName);
				}

				return result;
			}, [])
			.join(' > ');

		return modifiedSegmentName;
	};

	const onConfirm = () => {
		const checkedBoxes: Array<string> = [];

		if (allEqualChecker([...allSent, ...values], checked)) {
			checkedBoxes.push(PREDEFINED_SEGMENTS.SENT);
		} else if (allIncludedChecker(checked, [...allDelivered, ...values])) {
			checkedBoxes.push(PREDEFINED_SEGMENTS.DELIVERED);
		} else if (allIncludedChecker(checked, [...allRead, ...values])) {
			checkedBoxes.push(PREDEFINED_SEGMENTS.READ);
			checked.includes(PREDEFINED_SEGMENTS.UNDELIVERED) && checkedBoxes.push(PREDEFINED_SEGMENTS.UNDELIVERED);
		} else {
			checked.forEach((option) => [...allSent, ...values].includes(option) && checkedBoxes.push(option));
		}

		const modifiedSegmentName = modifySegmentName(checkedBoxes);
		const segmentDetails = {
			id: modifiedSegmentName.trim().replace(/\>$/, ''),
			label: modifiedSegmentName.trim().replace(/\>$/, ''),
			content: {},
		};
		setSegmentDetails(segmentDetails);

		const data = {
			retargetUsers: totalUsersTargetted,
			retargetSegments: segmentDetails,
			retargetPayload: {
				name: `${selectedBroadcastData?.broadcastTitle} > ${modifiedSegmentName.trim().replace(/\>$/, '')}`,
				target: checkedBoxes,
				id: selectedBroadcastData.broadcastId,
			},
			broadcastObjective: selectedBroadcastData?.broadcastObjective,
		};

		const queryString = `mode=${BROADCAST_TYPE.RETARGET}&payload=${encodeURIComponent(
			JSON.stringify(data)
		)}&path=retarget`;
		navigate(`${ThreeColumnRouteLinks.createBroadcast}?${queryString}`);
	};

	const renderRetargetBroadcast = () => (
		<div
			className={css({
				marginTop: '1rem',
				paddingRight: '2rem',
				marginRight: '10rem',
			})}
		>
			<CheckboxTree
				checked={checked}
				expanded={expanded}
				nodes={segmentNodes}
				onCheck={(checked) => setChecked(checked)}
				showNodeIcon={false}
				icons={{
					check: <CheckboxActive />,
					uncheck: <CheckboxInactive />,
					halfCheck: <CheckboxPartialActive />,
					expandOpen: <span style={{ cursor: 'none' }} />,
					expandClose: <span style={{ cursor: 'none' }} />,
				}}
			/>
		</div>
	);

	const callRetargetApi = (data: any) => {
		setLoading(true);
		const params = {
			targetApi: isBroadcastV2Enabled ? 'retargetOptionsV2' : 'retargetOptions',
		};
		const payload = {
			customerId: cid,
			botRef,
			broadcastId: data.broadcastId,
			platform: (data?.platforms).includes(PLATFORM.DIALOG360) ? PLATFORM.DIALOG360 : CHANNELS.WHATSAPP_ALL_CAPS,
			apiKey: '{{apiKey}}',
		};
		API.retargetOptions(payload, params)
			.then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					const treeData = [response.data.responseObject];
					setSegmentNodes(treeData);
					setIsRetargetBroadcastOpen(true);
				} else {
					setShowContactSupport(true);
				}
				setLoading(false);
			})
			.catch(() => {
				setShowErrorModal(true);
				setLoading(false);
			});
		setSelectedBroadcastData(data);
	};

	const RetargetBroadcastModal = () => {
		const retargetBroadcastContent = renderRetargetBroadcast();

		return (
			<ModalAlert
				closeBtnLabel={t('common:cancel')}
				confirmBtnLabel={t('common:next')}
				description={t('pages:broadcast.broadcastData.retargetDescription')}
				heading={t('pages:broadcast.broadcastData.retargetBroadcastTitle', {
					title: selectedBroadcastData.broadcastTitle,
				})}
				children={retargetBroadcastContent}
				isConfirmBtnDisabled={checked.length === 0 || totalUsersTargetted === 0}
				isOpen={isRetargetBroadcastOpen}
				width={values.length ? '30rem' : '25rem'}
				height='35%'
				onClose={() => {
					setChecked([]);
					setIsRetargetBroadcastOpen(false);
				}}
				closeable
				onConfirm={() => {
					onConfirm();
					setChecked([]);
					setIsRetargetBroadcastOpen(false);
				}}
				overrides={{
					ModalHeader: {
						wordWrap: 'break-word',
					},
				}}
			/>
		);
	};

	const NoDataRetargetModal = () => (
		<InfoModalBase
			onCloseFn={() => setNoDataForRetarget(false)}
			heading={t('pages:broadcast.broadcastData.retargetBroadcast')}
			isModalOpen={noDataForRetarget}
			description={t('pages:broadcast.broadcastData.noDataDescription')}
		/>
	);

	const ErrorModal = () => (
		<ModalAlert
			closeBtnLabel={errorModalDetails.cancelBtnLabel}
			confirmBtnLabel={errorModalDetails.confirmBtnLabel}
			description={errorModalDetails.description}
			heading={errorModalDetails.heading}
			isConfirmBtnDisabled={false}
			isOpen={showContactSupport}
			onClose={() => setShowContactSupport(false)}
			onConfirm={() => redirectFromModalAlert(supportEmailOrLink)}
			width='25rem'
		/>
	);

	const BroadcastHistoryBody = () => (
		<>
			<LayoutHeader>
				<div
					className={css({
						display: 'flex',
						flexDirection: 'column',
						gap: '0.5rem',
					})}
				>
					{broadcastHeader()}
					{!isSmallDesktopScreen &&
						(!marketingCampaignsAnalyticsType ? (
							<>
								<BroadcastFilter />
								{data.length > 0 && (
									<div
										className={css({
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											flexFlow: 'wrap',
											gap: '2rem',
											marginBottom: '1rem',
											marginRight: marketingCampaignsAnalyticsType ? '0.5rem' : '5rem',
										})}
									>
										<LabelSmall
											className={css({
												fontWeight: 600,
												fontSize: '1rem',
												marginTop: '1rem',
											})}
										>
											{t('pages:broadcast.broadcastHistory.broadcastList')}
										</LabelSmall>
										{renderSortAndMenu()}
									</div>
								)}
							</>
						) : (
							<div
								className={css({
									display: 'flex',
									flexDirection: 'row',
									gap: '0.5rem',
									justifyContent: 'space-between',
								})}
							>
								<BroadcastFilter />
								{renderSortAndMenu()}
							</div>
						))}
				</div>
			</LayoutHeader>
			<LayoutContent>
				<ENGTTable
					columns={broadcastColumns}
					rows={data}
					pagination={paginationMethods}
					paginationType='footer'
					overrides={tableOverrides}
					loader={isLoading || loading}
					rowActions={renderPublishedRowActions}
					customSizeOptions={[10]}
					customHeaderHeight='3rem'
					emptyDataView={
						<NoDataTemplate
							bannerImage={{
								altText: 'no-broadcast',
								imageUrl: NoBroadCastIcon,
							}}
							imageCss={{ marginBottom: '1rem' }}
							heading={t('pages:broadcast.basic.noPublishedHeading')}
							headingCss={{ marginTop: '1rem', marginBottom: '1rem', fontFamily: fontStyle }}
							footer={createNoDataTemplateFooter()}
						>
							{child}
						</NoDataTemplate>
					}
				/>
			</LayoutContent>
		</>
	);

	return (
		<>
			{marketingCampaignsAnalyticsType ? (
				BroadcastHistoryBody()
			) : (
				<Layout content={{ span: [12, 12, 11] }}>{BroadcastHistoryBody()}</Layout>
			)}
			{retryBroadcastModal && (
				<RetryBroadcastModal broadcastId={selectedBroadcastId} broadcastHelpers={broadcastHelperProps} />
			)}
			<ContactSalesModal
				isModalOpen={contactSales}
				toggleModal={setContactSales}
				options={{ contactMode: 'contact' }}
				heading={t('pages:broadcast.actions.contactSupport')}
			/>
			{whatsappNotConfigured && (
				<WhatsappConfigurationModal setWhatsappNotConfigured={setWhatsappNotConfigured} />
			)}
			{isStopModalOpen && <StopBroadcastModal toggleStop={toggleStopModal} onConfirm={onConfirmStop} />}
			{showErrorModal && <WarningModal warning={warning} setShowErrorModal={setShowErrorModal} />}
			<InfoModalBase
				onCloseFn={() => setViewNoUsersModal(false)}
				heading={t('pages:broadcast.basic.noData')}
				isModalOpen={viewNoUsersModal}
				description={t('pages:broadcast.basic.noDataInViewBroadcast')}
			/>
			{isWhatsappAcceleratorTrial && <BroadcastSuccessWithNudge onClose={onPaginationChange} />}
			{isRetargetBroadcastOpen && RetargetBroadcastModal()}
			{noDataForRetarget && NoDataRetargetModal()}
			{showContactSupport && ErrorModal()}
		</>
	);
}

export default BroadcastHistory;

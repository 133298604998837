import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTLink from 'components/UI/ENGTLink/ENGTLink';
import ENGTNudge, { NUDGE_STYLES } from 'components/UI/ENGTNudge/ENGTNudge';
import ENGTModalWithoutFooter from 'components/UI/Modal/ModalWithoutFooter/ModalWithoutFooter';

import { IObjProps } from 'shared/consts/types';
import { LiveChatJourney, WA_ACCELERATOR_JOURNEY_PHASE } from 'shared/enum';
import { PlgGTMTrackingIds } from 'shared/enum/trackingEnums';
import { getResponsiveVH, isValidResponseObject } from 'shared/helpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import FirstBroadcastCompleted from 'shared/icons/Broadcast/FirstBroadcastCompleted.svg';
import GoToLiveChat from 'shared/icons/Broadcast/GoToLivechatAnalytics.svg';
import EmailAcknowledgment from 'shared/icons/EmailAcknowledgment.svg';
import { sendPLGButtonClickEventToGA4 } from 'shared/trackingHelpers';

import { contactsAPI } from 'store/App/Contacts/api';
import { updateCapturedLeadsJourneyData } from 'store/App/User';
import { journeyPhaseSelector } from 'store/App/User/selectors';
import { publishedBroadcastList } from 'store/Broadcast/selectors';
import { messageListAPI } from 'store/Message';
import { RootState } from 'store/rootReducer';

import { TwoColumnRouteLinks } from 'router/links/TwoColumnRouteLinks';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

interface IContactDataProps {
	contactMetaId: string;
	broadcastName: string;
}

interface IPreviewProps {
	isOpen: boolean;
	isLoading: boolean;
}

enum CONTACT_METADATA {
	CONTACT_META_ID = 'contactMetaID',
	BROADCAST_NAME = 'broadcastName',
}

const { CONTACT_META_ID, BROADCAST_NAME } = CONTACT_METADATA;
const { FIRST_BROADCAST_SENT, STARTED_TRIAL_CONVERSATION } = WA_ACCELERATOR_JOURNEY_PHASE;

const BroadcastSuccessModal = ({ onClose }: { onClose: () => void }) => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components']);

	const dispatch = useDispatch<any>();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const userEmail = useSelector((state: RootState) => state.User.botAdmin.data.email);
	const customerName = useSelector((state: RootState) => state.User.botAdmin.data.name);
	const activeModule = useSelector((state: RootState) => state.User.botAdmin.data.active_module);

	const [isModalOpen, setModalOpen] = useState<boolean>(true);
	const [contactParams, setContactParams] = useState<IContactDataProps>({ contactMetaId: '', broadcastName: '' });

	const setAgentOnline = () => {
		const params = {
			targetApi: 'toggleStatus',
		};
		const body = {
			bot_ref: '{{botRef}}',
			agent_id: '{{userId}}',
			is_online: true,
			module: activeModule,
			cid: '{{customerId}}',
		};
		messageListAPI.setAgentOnlineStatus(params, body);
	};

	const sendEmailAndRedirect = (path: string) => {
		sendPLGButtonClickEventToGA4({
			gtmTrackingId:
				path === NoColumnRouteLinks.billingPlans
					? PlgGTMTrackingIds.BROADCAST_PUBLISHED_MODAL_PRICING_PAGE_CLICK
					: PlgGTMTrackingIds.BROADCAST_PUBLISHED_MODAL_EXPLORE_MORE_CLICK,
		});
		sendEmail();
		navigate(path);
	};

	const redirectToLivechat = () => {
		const path = TwoColumnRouteLinks.simplifiedMessages;
		sendPLGButtonClickEventToGA4({
			gtmTrackingId: PlgGTMTrackingIds.BROADCAST_PUBLISHED_MODAL_LIVECHAT_CLICK,
		});
		setAgentOnline();
		sendEmailAndRedirect(`${path}?${LiveChatJourney.BASE_PARAM}=${LiveChatJourney.START_CONVERSATION}`);
	};

	useEffect(() => {
		setContactParams({
			contactMetaId: searchParams.get(CONTACT_META_ID) || '',
			broadcastName: searchParams.get(BROADCAST_NAME) || '',
		});
	}, []);

	useEffect(() => {
		searchParams.delete(CONTACT_META_ID);
		searchParams.delete(BROADCAST_NAME);

		navigate(`?${searchParams.toString()}`, { replace: true });
	}, []);

	const onCloseModal = useCallback(() => {
		sendEmail();
		setModalOpen(false);
		onClose();
	}, [onClose]);

	const sendEmail = useCallback(() => {
		const params = {
			targetApi: 'sendEmailWithDummyData',
		};
		const body = {
			customerEmail: userEmail,
			contactMetadataId: contactParams.contactMetaId,
			broadcastName: contactParams.broadcastName,
			timestamp: new Date().toISOString(),
			customerName,
		};
		contactsAPI.sendEmailWithDummyData(params, body).then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				dispatch(updateCapturedLeadsJourneyData());
			}
		});
	}, [onCloseModal, searchParams]);

	const styles = {
		welcomeModal: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			gap: '0.5rem',
			paddingBottom: '2rem',
		},
		image: {
			paddingLeft: '3rem',
			paddingRight: '3rem',
		},
		heading: {
			fontSize: '1.25rem',
			lineHeight: '1.625rem',
			fontWeight: 700,
			color: theme.colors.primaryA,
		},
		description: {
			fontSize: '1rem',
			lineHeight: '1.25rem',
			fontWeight: 400,
			color: theme.colors.primaryA,
			textAlign: 'center',
			display: 'flex',
			margin: 0,
			flexDirection: 'column',
		},
	};

	return isModalOpen ? (
		<ENGTModalWithoutFooter
			heading=''
			isOpen={isModalOpen}
			onClose={onCloseModal}
			width='min-content'
			overrides={{
				Header: {
					borderBottomStyle: 'none',
				},
			}}
		>
			<div className={css(styles.welcomeModal)}>
				<ENGTImage src={FirstBroadcastCompleted} className={css(styles.image)} />
				<span className={css(styles.heading)}>
					{t('components:firstBroadcastcomplete.broadcastPublishedMessage')}
				</span>
				<p className={css(styles.description)}>
					<span className={css({ marginBottom: '1rem', opacity: '0.9' })}>
						{t('components:firstBroadcastcomplete.description1')}
					</span>
					<Block
						className={css({
							display: 'flex',
							alignItems: 'flex-start',
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
							paddingLeft: '1rem',
							paddingBottom: '0.5rem',
							fontWeight: 500,
							color: theme.colors.primaryA,
						})}
					>
						<ENGTImage src={EmailAcknowledgment} />
						{t('components:firstBroadcastcomplete.description2')}
					</Block>
				</p>
				<Block display='flex' gridGap='1rem'>
					<ENGTButton
						size={SIZE.large}
						kind={KIND.secondary}
						label={t('components:firstBroadcastcomplete.tryInbox')}
						onClick={redirectToLivechat}
						className={css({ whiteSpace: 'nowrap' })}
					/>
					<ENGTButton
						size={SIZE.large}
						kind={KIND.primary}
						label={t('pages:upgradeRequestForWAAccelerator.viewPricing')}
						onClick={() => sendEmailAndRedirect(NoColumnRouteLinks.billingPlans)}
						className={css({ whiteSpace: 'nowrap' })}
					/>
				</Block>
				<ENGTLink
					link={t('components:firstBroadcastcomplete.exploreMore')}
					onClick={() => sendEmailAndRedirect(TwoColumnRouteLinks.waQuickNavigator)}
					openInNewTab={false}
					overrides={{
						marginTop: '0.5rem',
						fontWeight: 600,
						color: theme.colors.accent,
						fontSize: '1.05rem',
					}}
				/>
			</div>
		</ENGTModalWithoutFooter>
	) : (
		<></>
	);
};

const BroadcastToLiveChatNudge = ({ isOpen }: { isOpen: boolean }) => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components']);
	const broadcastData = useSelector(publishedBroadcastList);

	const journeyPhaseDetails = useSelector(journeyPhaseSelector);

	const activeModule = useSelector((state: RootState) => state.User.botAdmin.data.active_module);

	const navigate = useNavigate();

	const { data: journeyPhaseData } = journeyPhaseDetails;

	const { isSmallDesktopScreen } = useResponsiveSize();

	const defaultPosition = useRef({
		top: getResponsiveVH(20),
		left: '5vw',
	});

	const setAgentOnlineAndNavigate = () => {
		const params = {
			targetApi: 'toggleStatus',
		};
		const body = {
			bot_ref: '{{botRef}}',
			agent_id: '{{userId}}',
			is_online: true,
			module: activeModule,
			cid: '{{customerId}}',
		};
		messageListAPI.setAgentOnlineStatus(params, body);
		navigate(
			`${TwoColumnRouteLinks.simplifiedMessages}?${LiveChatJourney.BASE_PARAM}=${LiveChatJourney.START_CONVERSATION}`
		);
	};

	const isFirstBroadcast = broadcastData.data.length === 1 || !journeyPhaseData[FIRST_BROADCAST_SENT];

	const styles = useMemo(() => NUDGE_STYLES(theme), [theme]);

	return isFirstBroadcast ? (
		<ENGTNudge isOpen={isOpen} targetId='messages-icon' defaultPosition={defaultPosition.current}>
			<div className={css({ ...styles.wrapper, minWidth: '13rem' })}>
				{!isSmallDesktopScreen && <ENGTImage src={GoToLiveChat} />}
				<div className={css(styles.data)}>
					<span className={css(styles.description)}>
						{isSmallDesktopScreen ? t('components:nudges.lcViewMobile') : t('components:nudges.lcView')}
					</span>
					<ENGTButton
						size={SIZE.mini}
						kind={KIND.primary}
						label={t(`common:goTo`, { component: t('common:liveChat') })}
						onClick={setAgentOnlineAndNavigate}
						className={css(styles.button)}
					/>
				</div>
			</div>
		</ENGTNudge>
	) : (
		<></>
	);
};

const BroadcastSuccessWithNudge = ({ onClose }: { onClose: () => any }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isNudgeOpen, setNudgeOpen] = useState<boolean>(false);

	const journeyPhaseDetails = useSelector(journeyPhaseSelector);

	const { data: journeyPhaseData } = journeyPhaseDetails;
	const queryParams = new URLSearchParams(location.search);

	useEffect(() => {
		if (queryParams.get(CONTACT_META_ID)) {
			setIsModalOpen(true);
		}
	}, [queryParams]);

	const onModalclose = useCallback(() => {
		setIsModalOpen(false);
		if (!journeyPhaseData[STARTED_TRIAL_CONVERSATION]) {
			setTimeout(() => {
				setNudgeOpen(true);
			}, 10000);
			setTimeout(() => {
				setNudgeOpen(false);
			}, 30000);
		}
		onClose();
	}, [setNudgeOpen, journeyPhaseDetails]);

	return (
		<>
			{isModalOpen && <BroadcastSuccessModal onClose={onModalclose} />}
			<BroadcastToLiveChatNudge isOpen={isNudgeOpen} />
		</>
	);
};

export default BroadcastSuccessModal;
export { BroadcastSuccessWithNudge };

import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Button, KIND, SHAPE, SIZE } from 'baseui/button';
import { HeadingLevel } from 'baseui/heading';
import { ParagraphMedium } from 'baseui/typography';
import toaster from 'baseui-new/toast/toaster';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTBarGroupY from 'components/UI/Graph/ENGTBarGroupY';
import ENGTLinePath from 'components/UI/Graph/ENGTLinePath';
import Loader from 'components/UI/Loader/Loader';
import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';

import { circularButtonCss } from 'shared/consts/consts';
import { LS_KEYS, readFromLs } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';
import { DAYS_FILTER } from 'shared/enum';
import { isValidResponseObject } from 'shared/helpers';
import { isValidPercentIncrease } from 'shared/helpers/broadcastHelpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import ArrowIcon from 'shared/icons/Broadcast/ArrowIcon';
import FilledDownCaret from 'shared/icons/FilledDownCaret';
import FilledUpCaret from 'shared/icons/FilledUpCaret';
import NoGraphIcon from 'shared/icons/NoGraphIcon.svg';

import { botNameSelector } from 'store/Bot/selectors';
import { API } from 'store/Broadcast/api';
import { RootState } from 'store/withReducer';

import InfoCardWithIcon from 'pages/BroadcastRevamp/ClickToWhatsappAds/components/InfoCardWithIcon';

import useExportEngagementHook from '../hooks/useExportEngagementHook';

import DaysFilter from './DaysFilter';

function BroadcastEngagement() {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);
	const { isSmallDesktopScreen } = useResponsiveSize();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [filterOn, setFilterOn] = useState<string>(() => {
		const savedFilter = readFromLs(LS_KEYS.ENGAGEMENT_FILTER);

		return savedFilter !== null ? savedFilter : DAYS_FILTER.DAYS_7;
	});
	const [broadcastEngagedUsersData, setBroadcastEngagedUsersData] = useState<IObjProps>([]);

	const profileData = useSelector((state: RootState) => state.User.profile.data);
	const botName = useSelector(botNameSelector);
	const { exportEngagementData } = useExportEngagementHook();

	const styles = {
		headingCss: {
			marginBottom: '0.3125rem',
			fontWeight: '700',
			fontSize: '1.125rem',
			marginTop: '0.7rem',
		},

		subHeadingCss: {
			marginTop: '1rem',
			lineHeight: '1.25rem',
			width: '100%',
			fontSize: '0.875rem !important',
			fontWeight: '400',
		},

		bodyCss: {
			marginTop: '0.5rem',
			display: 'grid',
			gridTemplateColumns: isSmallDesktopScreen ? '1fr' : '1fr 1fr',
			gridGap: '1rem',
		},

		infoCardCss: {
			display: 'inline-flex',
			alignItems: 'center',
			marginTop: '4rem',
			gap: '0.2rem',
			color: `${theme.colors.primaryA}`,
		},

		paragraphCss: {
			marginTop: '0.75rem',
			marginBottom: '0.3125rem',
			fontWeight: '700 !important',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	};

	useEffect(() => {
		getBroadcastEngagedUsers();
	}, [filterOn]);

	const getBroadcastEngagedUsers = () => {
		setLoading(true);
		API.getBroadcastEngagedUsers(filterOn)
			.then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					setBroadcastEngagedUsersData(response.data.responseObject);
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
						{}
					);
					setBroadcastEngagedUsersData([]);
				}
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleExportClick = () => {
		const data = {
			filename: `broadcast_engagement_${botName}`,
			workflow: 'BROADCAST_ENGAGEMENT_USERS_EXPORT',
			filter: filterOn,
		};
		exportEngagementData(data);
	};

	const showEngagedPercentIncrease = isValidPercentIncrease(
		broadcastEngagedUsersData?.broadcastEngagement?.engagedPercentIncrease
	);

	const showDeliveredPercentIncrease = isValidPercentIncrease(
		broadcastEngagedUsersData?.broadcastEngagement?.deliveredPercentIncrease
	);

	const broadcastTotalEngagement = (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem' }}>
				<ParagraphMedium
					color={theme.colors.primaryA}
					className={css({
						...styles.headingCss,
						display: 'flex',
					})}
				>
					{t('pages:marketingCampaigns.engagement.broadcastEngagement')}
					<Button
						size={SIZE.large}
						shape={SHAPE.circle}
						kind={KIND.secondary}
						overrides={{
							BaseButton: {
								style: {
									...circularButtonCss,
									marginLeft: '0.5rem',
									height: '1.7rem',
									width: '1.7rem',
								},
							},
						}}
						onClick={handleExportClick}
					>
						<ArrowIcon width={22} height={22} />
					</Button>
				</ParagraphMedium>
				<DaysFilter filterOn={setFilterOn} />
			</div>

			<ParagraphMedium color={theme.colors.primaryA} className={css(styles.subHeadingCss)}>
				{t('pages:marketingCampaigns.engagement.broadcastEngagementDescription')}
			</ParagraphMedium>

			<div className={css(styles.bodyCss)}>
				<InfoCardWithIcon
					title={<strong>{t('pages:marketingCampaigns.engagement.engagedUsers')}</strong>}
					value={broadcastEngagedUsersData?.broadcastEngagement?.engagedCount || 0}
					marginTop='2rem'
				>
					{showEngagedPercentIncrease ? (
						<div className={css(styles.infoCardCss)}>
							{broadcastEngagedUsersData.broadcastEngagement.engagedPercentIncrease > 0 ? (
								<FilledUpCaret
									fillColor={theme.colors.dotStatusFill}
									height={12}
									width={14}
									pathToDraw='M8.0752 1.24658C7.55424 0.497235 6.44576 0.497235 5.92481 1.24658L0.236574 9.42852C-0.36707 10.2968 0.254272 11.4855 1.31177 11.4855L12.6882 11.4855C13.7457 11.4855 14.3671 10.2968 13.7634 9.42852L8.0752 1.24658Z'
								/>
							) : (
								<FilledDownCaret
									fillColor={theme.colors.dotPendingFill}
									height={12}
									width={14}
									pathToDraw='M8.0752 10.7362C7.55424 11.4855 6.44576 11.4855 5.92481 10.7362L0.236574 2.55426C-0.36707 1.68598 0.254272 0.497235 1.31177 0.497235L12.6882 0.497235C13.7457 0.497235 14.3671 1.68598 13.7634 2.55426L8.0752 10.7362Z'
								/>
							)}

							<span style={{ fontWeight: 700 }}>
								{broadcastEngagedUsersData.broadcastEngagement.engagedPercentIncrease}
							</span>
							{t('% ') +
								t('pages:marketingCampaigns.engagement.percentIncrease', {
									calenderTime:
										filterOn === DAYS_FILTER.DAYS_7
											? t('pages:marketingCampaigns.week')
											: t('pages:marketingCampaigns.month'),
								})}
						</div>
					) : (
						<div style={{ color: `${theme.colors.primaryA}`, marginTop: '4rem' }}>
							{t('pages:marketingCampaigns.engagement.noTrendAvailable')}
						</div>
					)}
				</InfoCardWithIcon>

				<InfoCardWithIcon
					title={<strong>{t('pages:marketingCampaigns.engagement.delivered')}</strong>}
					value={broadcastEngagedUsersData?.broadcastEngagement?.deliveredCount || 0}
					marginTop='2rem'
				>
					{showDeliveredPercentIncrease ? (
						<div className={css(styles.infoCardCss)}>
							{broadcastEngagedUsersData.broadcastEngagement.deliveredPercentIncrease > 0 ? (
								<FilledUpCaret
									fillColor={theme.colors.dotStatusFill}
									height={12}
									width={14}
									pathToDraw='M8.0752 1.24658C7.55424 0.497235 6.44576 0.497235 5.92481 1.24658L0.236574 9.42852C-0.36707 10.2968 0.254272 11.4855 1.31177 11.4855L12.6882 11.4855C13.7457 11.4855 14.3671 10.2968 13.7634 9.42852L8.0752 1.24658Z'
								/>
							) : (
								<FilledDownCaret
									fillColor={theme.colors.dotPendingFill}
									height={12}
									width={14}
									pathToDraw='M8.0752 10.7362C7.55424 11.4855 6.44576 11.4855 5.92481 10.7362L0.236574 2.55426C-0.36707 1.68598 0.254272 0.497235 1.31177 0.497235L12.6882 0.497235C13.7457 0.497235 14.3671 1.68598 13.7634 2.55426L8.0752 10.7362Z'
								/>
							)}

							<span style={{ fontWeight: 700 }}>
								{broadcastEngagedUsersData.broadcastEngagement.deliveredPercentIncrease}
							</span>
							{t('% ') +
								t('pages:marketingCampaigns.engagement.percentIncrease', {
									calenderTime:
										filterOn === DAYS_FILTER.DAYS_7
											? t('pages:marketingCampaigns.week')
											: t('pages:marketingCampaigns.month'),
								})}
						</div>
					) : (
						<div style={{ color: `${theme.colors.primaryA}`, marginTop: '4rem' }}>
							{t('pages:marketingCampaigns.engagement.noTrendAvailable')}
						</div>
					)}
				</InfoCardWithIcon>
			</div>
		</>
	);

	const showBroadcastEngagementAnalytics = () => {
		const formatDate = (dateString: string) => {
			const [startYear, startMonth, startDay, endYear, endMonth, endDay] = dateString.split('-');

			return `${startDay}/${startMonth} - ${endDay}/${endMonth}`;
		};

		const weekDataKey = (users: string) => {
			const noOfWeeks = 4;

			return Object.keys(broadcastEngagedUsersData?.broadcastEngagementMap).length === noOfWeeks
				? formatDate(users)
				: users;
		};

		const formatDataForStackBar = () =>
			Object.entries(broadcastEngagedUsersData.broadcastEngagementMap).map(([key, userHistory]: any) => ({
				text: weekDataKey(key),
				[t('pages:marketingCampaigns.engagement.engaged')]: userHistory?.engagedCount,
				[t('pages:marketingCampaigns.engagement.delivered')]: userHistory?.totalCount,
			}));

		const formatDataForLineGraph = () =>
			Object.entries(broadcastEngagedUsersData?.broadcastEngagementMap).map(([key, value]: any) => ({
				date: key,
				du: value.engagementRate,
			}));

		return (
			<div className={css({ height: '19.563rem' })}>
				<div style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
					<HeadingLevel>
						<ParagraphMedium
							color={theme.colors.primaryA}
							className={css({
								...styles.paragraphCss,
							})}
						>
							{t('pages:marketingCampaigns.engagement.broadcast')}
							{broadcastEngagedUsersData?.lastUpdatedOn && (
								<div className={css({ fontWeight: 400, fontSize: '0.875rem', alignItems: 'center' })}>
									{t('components:conversations.lastUpdatedOn')}
									<span style={{ marginLeft: '0.2rem' }}>
										{`${t('common:standardDateTimeFormatterWithShortMonthNameAndTimeAheadOfDate', {
											date: broadcastEngagedUsersData.lastUpdatedOn,
										})} (${profileData.timezone})`}
									</span>
								</div>
							)}
						</ParagraphMedium>
					</HeadingLevel>
				</div>

				<ParentSize className={css({ marginTop: '0.75rem', paddingBottom: '7rem' })}>
					{({ width, height }) => (
						<ENGTBarGroupY
							width={width || 917}
							height={400}
							keys={[
								t('pages:marketingCampaigns.engagement.engaged'),
								t('pages:marketingCampaigns.engagement.delivered'),
							]}
							barRadius={0}
							colors={[`${theme.colors.royalBlue}`, `${theme.colors.raspberry}`]}
							textColor={theme.colors.primaryA}
							background={theme.colors.inputFillPrimary}
							axisBottomLeft={-40}
							data={formatDataForStackBar().reverse()}
							accessor={(data: IObjProps) => data.text}
							margin={{
								top: 80,
								right: 50,
								bottom: 30,
								left: 70,
							}}
							padding={{
								inner: 0.2,
								outer: 2.5,
							}}
							xFormatter={(data: IObjProps) => data}
						/>
					)}
				</ParentSize>

				<ParagraphMedium
					color={theme.colors.primaryA}
					className={css({ marginTop: '0.5rem', marginBottom: '0.3125rem', fontWeight: '700' })}
				>
					{t('pages:marketingCampaigns.engagement.engagementRate')}
				</ParagraphMedium>
				<ParentSize
					className={css({ marginTop: isSmallDesktopScreen ? '2rem' : '0.5rem', paddingBottom: '8rem' })}
				>
					{({ width, height }) => (
						<ENGTLinePath
							width={width}
							height={400}
							data={formatDataForLineGraph().reverse()}
							xAccessor={(data: IObjProps) => weekDataKey(data.date)}
							background={theme.colors.inputFillPrimary}
							axisBottomLeft={-40}
							margin={{
								top: 80,
								left: 70,
								bottom: 30,
								right: 70,
							}}
							paths={[
								{
									stroke: 4,
									color: `${theme.colors.engatiYellow}`,
									key: 'engagementRate',
									yAccessor: (data: IObjProps) => data.du,
									labelFormat: t('pages:marketingCampaigns.engagement.engagementRate'),
								},
							]}
						/>
					)}
				</ParentSize>
			</div>
		);
	};

	const autoRetryCount = (
		<div
			className={css({
				color: theme.colors.primaryA,
				fontSize: '0.875rem',
				marginTop: '1rem',
			})}
		>
			🌟{' '}
			<span
				className={css({
					color: theme.colors.transactionGreen,
					fontWeight: '700',
				})}
			>
				{broadcastEngagedUsersData?.deliveredUsingAutoRetry}
			</span>
			{t('pages:marketingCampaigns.engagement.broadcastUsingAutoRetry')}
		</div>
	);

	return isLoading ? (
		<Loader loaderBlockHeight='100%' />
	) : (
		<>
			{broadcastTotalEngagement}
			{autoRetryCount}
			<HorizontalDivider
				height='0.5px'
				overrides={{ width: '100%', borderTop: '1.5px solid', marginTop: '1rem' }}
				color={theme.colors.accent100}
				borderColor={theme.colors.accent100}
			/>

			{broadcastEngagedUsersData?.broadcastEngagement?.engagedCount ? (
				showBroadcastEngagementAnalytics()
			) : (
				<NoDataTemplate
					heading={t('pages:broadcast.analytics.noData')}
					bannerImage={{
						altText: 'No Graph Available Icon',
						imageUrl: NoGraphIcon,
					}}
				/>
			)}
		</>
	);
}

export default BroadcastEngagement;

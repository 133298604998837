import { IObjProps } from 'shared/consts/types';

import { ImessageListStateInterface } from './state';

export const messageListReducer = {
	getAvailableAgents: (state: ImessageListStateInterface, action: { payload: Array<IObjProps> }) => {
		state.allAgents = {
			data: action.payload,
			loading: false,
			error: false,
		};
	},
	getAllTabAvailableAgents: (state: ImessageListStateInterface, action: { payload: Array<IObjProps> }) => {
		state.allTabAgents = {
			data: action.payload,
			loading: false,
			error: false,
		};
	},
	getAvailableAgentsLoading: (state: ImessageListStateInterface) => {
		state.allAgents = {
			data: [],
			loading: true,
			error: false,
		};
	},
	getLiveChatUsers: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.userList = {
			data: action.payload.content,
			error: false,
			loading: false,
			pagination: {
				page: action.payload.number + 1,
				size: action.payload.size,
				totalPages: action.payload.totalPages,
				totalElements: action.payload.totalElements,
			},
			tab: action.payload.tab,
		};
	},
	getLiveChatUsersLoading: (state: ImessageListStateInterface) => {
		state.userList = {
			...state.userList,
			data: [],
			loading: true,
			error: false,
		};
	},
	getLiveChatUsersError: (state: ImessageListStateInterface) => {
		state.userList = {
			...state.userList,
			data: [],
			error: true,
			loading: false,
		};
	},
	setSelectedUser: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.selectedUser = action.payload;
	},
	getUsersSolarQuery: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.userList = {
			data: action.payload.content,
			loading: false,
			error: false,
			pagination: {
				page: action.payload.number + 1,
				size: action.payload.size,
				totalPages: action.payload.totalPages,
				totalElements: action.payload.totalElements,
			},
			tab: action.payload.tab,
		};
	},

	setAllUsersCount: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.usersCount = action.payload;
	},
	setNewAndActiveUsersCount: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.usersCount = { ...state.usersCount, active: action.payload.active, new: action.payload.new };
	},
	updateAllUsersCount: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.usersCount = action.payload;
	},
	setUserList: (state: ImessageListStateInterface, action: { payload: Array<IObjProps> }) => {
		// let oldUserList = JSON.parse(JSON.stringify(state.userList));
		state.userList = {
			pagination: state.userList.pagination,
			data: action.payload,
			tab: state.userList.tab,
			loading: false,
			error: false,
		};
	},
	setMessageCount: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.messageCountMap = action.payload;
	},
	setConversationReadStatus: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.conversationReadStatusMap = action.payload;
	},
	setSentimentData: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.sentiment.data = action.payload;
	},
	setSentimentAttributeData: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.sentiment.attributeId = action.payload;
	},
	setAgentData: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.agent = action.payload;
	},
	setPathList: (state: ImessageListStateInterface, action: { payload: Array<IObjProps> }) => {
		state.pathList = action.payload;
	},
	setBotLevelTagsList: (state: ImessageListStateInterface, action: { payload: Array<string> }) => {
		state.botLevelTagsList = [...(state.botLevelTagsList || []), ...action.payload];
	},
	setUserLevelTags: (state: ImessageListStateInterface, action: { payload: Array<string> }) => {
		state.userLevelTags = [...action.payload];
	},
	setSelectedUserData: (state: ImessageListStateInterface, action: { payload: IObjProps }) => {
		state.conversationData = action.payload;
	},
	setStartConversationButton: (state: ImessageListStateInterface, action: { payload: boolean }) => {
		state.showStartConversationButton = action.payload;
	},
};

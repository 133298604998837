import { i18nHelper as t } from 'i18nHelper';

import { IObjProps } from './types';

const EMAIL_OR_ATTR_REGEX =
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|{{.*}}/;
const DATE_OR_ATTR_REGEX = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))|{{.*}}/;
const PHONE_OR_ATTR_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$|{{.*}}/;

export enum INPUT_TYPE {
	select = 'SELECT',
	text = 'TEXT',
	listMap = 'LIST_MAP',
	textarea = 'TEXTAREA',
	radio = 'RADIO',
	tags = 'TAGS',
	checkbox = 'CHECKBOX',
}

export enum AdditionalFieldNames {
	MULTIVALUED = 'isMultiValuedSearch',
}

export enum DATA_TYPE {
	string = 'STRING',
	integer = 'INTEGER',
	list = 'LIST',
	boolean = 'BOOLEAN',
	date = 'DATE',
	attribute = 'ATTRIBUTE',
}

export enum WORKFLOW_KEYS {
	GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS',
	CREATE_TICKET_GORGIAS = 'CREATE_TICKET_GORGIAS',
	UPDATE_TICKET_GORGIAS = 'UPDATE_TICKET_GORGIAS',
	SEARCH_RESOURCES_GORGIAS = 'SEARCH_RESOURCES_GORGIAS',
}

export enum INTEGRATIONS {
	GORGIAS = 'GORGIAS',
}

export enum PARAM_KEYS {
	tags = 'tags',
}

export const SMS_PROVIDERS = ['SMS_CLICKATELL', 'SMS_TWILIO'];
/**
 * specify the tabs to be shown for integration node, key value pairs in this object has to be like:
 * <integration_key> : { <workflow_key> : [tabTitle1,tabTitle2, etc] }
 */
export const INTEGRATION_WORKFLOWS_TABS: () => IObjProps = () => ({
	FRESHDESK: {
		GET_TICKET_FRESHDESK: [
			t('components:node.integrationNodes.action'),
			t('components:node.integrationNodes.retrieve'),
		],
		CREATE_TICKET_FRESHDESK: [t('components:node.integrationNodes.action')],
		UPDATE_TICKET_FRESHDESK: [t('components:node.integrationNodes.action')],
	},
	ZENDESK: {
		GET_TICKET_ZENDESK: [
			t('components:node.integrationNodes.action'),
			t('components:node.integrationNodes.retrieve'),
		],
		CREATE_TICKET_ZENDESK: [t('components:node.integrationNodes.action')],
		UPDATE_TICKET_ZENDESK: [t('components:node.integrationNodes.action')],
	},
	SMS_TWILIO: {
		SEND_SMS_TWILIO: [t('components:node.integrationNodes.action')],
	},
	SMS_CLICKATELL: {
		SEND_SMS_CLICKATELL: [t('components:node.integrationNodes.action')],
	},
	GOOGLE_SHEETS: {
		GOOGLE_SHEETS_ADD_ROW: [t('components:node.integrationNodes.action')],
		GOOGLE_SHEETS_SEARCH_ROW: [
			t('components:node.integrationNodes.action'),
			t('components:node.integrationNodes.retrieve'),
		],
		GOOGLE_SHEETS_UPDATE_ROW: [
			t('components:node.integrationNodes.action'),
			t('components:node.integrationNodes.update'),
		],
	},
	GOOGLE_CALENDAR: {
		GET_CALENDAR_EVENTS: [
			t('components:node.integrationNodes.google_calendar.slots'),
			t('components:node.integrationNodes.google_calendar.calendarDetails'),
			t('components:node.integrationNodes.google_calendar.restart'),
		],
		CREATE_GOOGLE_CALENDAR_EVENT: [t('components:node.integrationNodes.google_calendar.eventDetails')],
	},
	SALESFORCE: {
		SALESFORCE_ADD_ACCOUNT: [t('components:node.integrationNodes.action')],
		SALESFORCE_ADD_LEAD: [t('components:node.integrationNodes.action')],
		SALESFORCE_ADD_OPPORTUNITY: [t('components:node.integrationNodes.action')],
		SALESFORCE_ADD_CASE: [t('components:node.integrationNodes.action')],
		SALESFORCE_GET_CASE: [
			t('components:node.integrationNodes.action'),
			t('components:node.integrationNodes.retrieve'),
		],
		SALESFORCE_UPDATE_CASE: [t('components:node.integrationNodes.action')],
	},
	GORGIAS: {
		GET_TICKET_GORGIAS: [
			t('components:node.integrationNodes.action'),
			t('components:node.integrationNodes.retrieve'),
		],
		CREATE_TICKET_GORGIAS: [t('components:node.integrationNodes.action')],
		UPDATE_TICKET_GORGIAS: [t('components:node.integrationNodes.action')],
	},
});

/**
 * options with id, label to be shown in screen 2 of integration nodes for workflowKey field
 * <integration_key> : [{id: <workflow_key_option>, label: <label_for_workflow>}]
 */
export const INTEGRATION_WORKFLOWS: () => IObjProps = () => ({
	FRESHDESK: [
		{ id: 'GET_TICKET_FRESHDESK', label: t('components:node.integrationNodes.getTicket') },
		{ id: 'CREATE_TICKET_FRESHDESK', label: t('components:node.integrationNodes.createTicket') },
		{ id: 'UPDATE_TICKET_FRESHDESK', label: t('components:node.integrationNodes.updateTicket') },
	],
	ZENDESK: [
		{ id: 'GET_TICKET_ZENDESK', label: t('components:node.integrationNodes.getTicket') },
		{ id: 'CREATE_TICKET_ZENDESK', label: t('components:node.integrationNodes.createTicket') },
		{ id: 'UPDATE_TICKET_ZENDESK', label: t('components:node.integrationNodes.updateTicket') },
	],
	SMS_TWILIO: [{ id: 'SEND_SMS_TWILIO', label: t('components:node.integrationNodes.twilioSms') }],
	SMS_CLICKATELL: [{ id: 'SEND_SMS_CLICKATELL', label: t('components:node.integrationNodes.clickatellSms') }],
	GOOGLE_SHEETS: [
		{ id: 'GOOGLE_SHEETS_ADD_ROW', label: t('components:node.integrationNodes.google_sheets.addRow') },
		{ id: 'GOOGLE_SHEETS_SEARCH_ROW', label: t('components:node.integrationNodes.google_sheets.searchRow') },
		{ id: 'GOOGLE_SHEETS_UPDATE_ROW', label: t('components:node.integrationNodes.google_sheets.updateRow') },
	],
	GOOGLE_CALENDAR: [
		{ id: 'GET_CALENDAR_EVENTS', label: t('components:node.integrationNodes.google_calendar.getFreeSlots') },
		{
			id: 'CREATE_GOOGLE_CALENDAR_EVENT',
			label: t('components:node.integrationNodes.google_calendar.createEvent'),
		},
	],
	SALESFORCE: [
		{ id: 'SALESFORCE_ADD_ACCOUNT', label: t('components:node.integrationNodes.salesforce.addAccount') },
		{ id: 'SALESFORCE_ADD_LEAD', label: t('components:node.integrationNodes.salesforce.addLead') },
		{ id: 'SALESFORCE_ADD_OPPORTUNITY', label: t('components:node.integrationNodes.salesforce.addOpportunity') },
		{ id: 'SALESFORCE_ADD_CASE', label: t('components:node.integrationNodes.salesforce.addCase') },
		{ id: 'SALESFORCE_GET_CASE', label: t('components:node.integrationNodes.salesforce.getCase') },
		{ id: 'SALESFORCE_UPDATE_CASE', label: t('components:node.integrationNodes.salesforce.updateCase') },
	],
	GORGIAS: [
		{ id: 'GET_TICKET_GORGIAS', label: t('components:node.integrationNodes.getTicket') },
		{ id: 'CREATE_TICKET_GORGIAS', label: t('components:node.integrationNodes.createTicket') },
		{ id: 'UPDATE_TICKET_GORGIAS', label: t('components:node.integrationNodes.updateTicket') },
	],
});

export const STATIC_OPTIONS: () => IObjProps = () => ({
	priority: [
		{
			id: 1,
			label: t(`components:node.integrationNodes.labels.low`),
		},
		{
			id: 2,
			label: t(`components:node.integrationNodes.labels.medium`),
		},
		{
			id: 3,
			label: t(`components:node.integrationNodes.labels.high`),
		},
		{
			id: 4,
			label: t(`components:node.integrationNodes.labels.urgent`),
		},
	],
	status: [
		{
			id: 2,
			label: t(`components:node.integrationNodes.labels.open`),
		},
		{
			id: 3,
			label: t(`components:node.integrationNodes.labels.pending`),
		},
		{
			id: 4,
			label: t(`components:node.integrationNodes.labels.resolved`),
		},
		{
			id: 5,
			label: t(`components:node.integrationNodes.labels.closed`),
		},
		{
			id: 6,
			label: t(`components:node.integrationNodes.labels.waitOnCustomer`),
		},
		{
			id: 7,
			label: t(`components:node.integrationNodes.labels.waitOnThirdParty`),
		},
	],
	source: [
		{
			id: 1,
			label: t(`components:node.integrationNodes.labels.email`),
		},
		{
			id: 2,
			label: t(`components:node.integrationNodes.labels.portal`),
		},
		{
			id: 3,
			label: t(`components:node.integrationNodes.labels.phone`),
		},
		{
			id: 7,
			label: t(`components:node.integrationNodes.labels.chat`),
		},
		{
			id: 9,
			label: t(`components:node.integrationNodes.labels.feedbackWidget`),
		},
		{
			id: 10,
			label: t(`components:node.integrationNodes.labels.outboundEmail`),
		},
	],
	'ticket::type': [
		{
			id: 'incident',
			label: t(`components:node.integrationNodes.labels.incident`),
		},
		{
			id: 'problem',
			label: t(`components:node.integrationNodes.labels.problem`),
		},
		{
			id: 'question',
			label: t(`components:node.integrationNodes.labels.question`),
		},
		{
			id: 'task',
			label: t(`components:node.integrationNodes.labels.task`),
		},
	],
	'ticket::priority': [
		{
			id: 'urgent',
			label: t(`components:node.integrationNodes.labels.urgent`),
		},
		{
			id: 'high',
			label: t(`components:node.integrationNodes.labels.high`),
		},
		{
			id: 'normal',
			label: t(`components:node.integrationNodes.labels.normal`),
		},
		{
			id: 'low',
			label: t(`components:node.integrationNodes.labels.low`),
		},
	],
	'ticket::status': [
		{
			id: 'closed',
			label: t(`components:node.integrationNodes.labels.closed`),
		},
		{
			id: 'hold',
			label: t(`components:node.integrationNodes.labels.hold`),
		},
		{
			id: 'new',
			label: t(`components:node.integrationNodes.labels.new`),
		},
		{
			id: 'open',
			label: t(`components:node.integrationNodes.labels.open`),
		},
		{
			id: 'pending',
			label: t(`components:node.integrationNodes.labels.pending`),
		},
		{
			id: 'solved',
			label: t(`components:node.integrationNodes.labels.solved`),
		},
	],
	gorgiasStatus: [
		{
			id: 'open',
			label: t(`components:node.integrationNodes.labels.open`),
		},
		{
			id: 'closed',
			label: t(`components:node.integrationNodes.labels.closed`),
		},
	],
	channel: [
		{
			id: 'sms',
			label: t(`components:node.integrationNodes.gorgias.sms`),
		},
		{
			id: 'api',
			label: t(`components:node.integrationNodes.gorgias.api`),
		},
	],
});

export const CALENDAR_PARAM_MAP_DATA: any = {
	appt_date: {
		dataType: 'DATE',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
	},
	attendee: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
		metadata: '',
	},
	calendarId: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'SELECT',
	},
	desc: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
	},
	endTime: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
	},
	end_time_attr: {
		dataType: 'ATTRIBUTE',
		description: 'Attribute assigned to the ending time of the slot',
		inputType: 'TEXT',
		metadata: { processAttribute: true },
	},
	location: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
	},
	options_num: {
		dataType: 'INTEGER',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'SELECT',
	},
	show_time_zone: {
		dataType: 'BOOLEAN',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'RADIO',
	},
	slot_duration_hh: {
		dataType: 'INTEGER',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'SELECT',
	},
	slot_duration_mm: {
		dataType: 'INTEGER',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'SELECT',
	},
	startTime: { dataType: 'STRING', description: 'Specify a Value or a Select an Attribute', inputType: 'TEXT' },
	start_time_attr: {
		dataType: 'ATTRIBUTE',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
		metadata: { processAttribute: true },
	},
	summary: { dataType: 'STRING', description: 'Specify a Value or a Select an Attribute', inputType: 'TEXT' },
	timeMax: {
		dataType: 'DATE',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
	},
	timeMin: {
		dataType: 'DATE',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
	},
	time_zone: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'SELECT',
	},
	time_slot_pref: {
		dataType: 'DATE',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
	},
	sendNotifications: {
		dataType: 'BOOLEAN',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'CHECKBOX',
	},
	sendMeetLink: {
		dataType: 'BOOLEAN',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'CHECKBOX',
	},
	show_more: {
		dataType: 'BOOLEAN',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'RADIO',
	},
	show_more_label: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
	},
};

export const SALESFORCE_PARAM_MAP_DATA = {
	caseNumber: { dataType: 'STRING', description: 'Please specify the case number', inputType: 'TEXT' },
	CommentBody: { dataType: 'STRING', description: 'Add a comment', inputType: 'TEXTAREA' },
	ContactId: {
		dataType: 'STRING',
		description: 'Contact pesron for  case',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	Origin: {
		dataType: 'STRING',
		description: 'Type of cae origin e.g. web,email,mobile',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	PotentialLiability__c: { dataType: 'STRING', description: '', inputType: 'SELECT', inputValidation: '(^.*$)' },
	Priority: {
		dataType: 'STRING',
		description: 'Status of cae e.g. New,working,closed',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	Product__c: {
		dataType: 'STRING',
		description: 'Status of cae e.g. G001,G002',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	Reason: {
		dataType: 'STRING',
		description: 'Type of cae reason e.g. installation',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	SLAViolation__c: {
		dataType: 'STRING',
		description: 'e.g. yes or no',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	Subject: {
		dataType: 'STRING',
		description: 'Title/Topic of the issue',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	SuppliedEmail: { dataType: 'STRING', description: 'Email', inputType: 'TEXT' },
	AccountId: {
		dataType: 'STRING',
		description: 'Account to be used for creating oppurtunity',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	Amount: {
		dataType: 'DOUBLE',
		description: 'Amount to be specified in oppurtunity',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	CampaignId: {
		dataType: 'STRING',
		description: 'Primary Campaign source of oppurtunity',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	CloseDate: {
		dataType: 'DATE',
		description: 'Closing date of oppurtunity',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	ForecastCategoryName: {
		dataType: 'STRING',
		description: 'Forecast category of oppurtunity',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	IsPrivate: {
		dataType: 'BOOLEAN',
		description: 'Should this be private',
		inputType: 'RADIO',
		inputValidation: '(^.*$)',
	},
	NextStep: {
		dataType: 'STRING',
		description: 'Next step for oppurtunity',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	Probability: {
		dataType: 'DOUBLE',
		description: 'Probablity of conversion of oppurtunity',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	StageName: {
		dataType: 'STRING',
		description: 'In which stage oppurtunity should be',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	AccountNumber: {
		dataType: 'STRING',
		description: 'Tracking or reference number for account. Up to 40 characters are allowed in this field.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	AnnualRevenue: {
		dataType: 'DOUBLE',
		description: 'Amount of annual reported revenue.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	BillingCity: {
		dataType: 'STRING',
		description: 'City portion of billing address. Up to 40 characters are allowed.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	BillingCountry: {
		dataType: 'STRING',
		description: 'Country portion of billing address',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	BillingPostalCode: {
		dataType: 'STRING',
		description: 'Zip or postal code portion of billing address. Up to 20 characters are allowed.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	BillingState: {
		dataType: 'STRING',
		description: 'State or province portion of billing address.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	BillingStreet: {
		dataType: 'STRING',
		description: 'Street address used for billing. Up to 255 characters are allowed in this field.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	City: { dataType: 'STRING', description: 'City', inputType: 'TEXT', inputValidation: '(^.*$)' },
	Company: { dataType: 'STRING', description: 'Company of lead', inputType: 'TEXT', inputValidation: '(^.*$)' },
	Country: { dataType: 'STRING', description: 'Country', inputType: 'TEXT', inputValidation: '(^.*$)' },
	Description: {
		dataType: 'STRING',
		description: "A brief description of an organization's line of business.",
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	Email: { dataType: 'STRING', description: 'Email', inputType: 'TEXT', inputValidation: '(^.*$)' },
	Employees: {
		dataType: 'INTEGER',
		description: 'Number of people employed by the account.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	Fax: {
		dataType: 'STRING',
		description:
			'13235551234@efaxsend.com where 1 equals the country code; 323 is the area code; and 5551234 is the fax number.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	FirstName: {
		dataType: 'STRING',
		description: 'First name of the lead',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	Industry: {
		dataType: 'STRING',
		description: 'Primary business of account. Entry is selected from a picklist of available values',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	Name: {
		dataType: 'STRING',
		description: 'Account Name',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	Ownership: {
		dataType: 'STRING',
		description:
			'Ownership of company, for example, public or private. Entry is selected from a picklist of available values',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	LastName: {
		dataType: 'STRING',
		description: 'Last name of the lead',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	LeadSource: {
		dataType: 'STRING',
		description: 'Source of the lead',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	MobilePhone: { dataType: 'STRING', description: 'Mobile Phone', inputType: 'TEXT', inputValidation: '(^.*$)' },
	NumberOfEmployees: {
		dataType: 'INTEGER',
		description: 'Number of Employees',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	OwnerId: { dataType: 'STRING', description: 'Owner of Lead', inputType: 'SELECT', inputValidation: '(^.*$)' },
	Phone: {
		dataType: 'STRING',
		description: 'Account Phone',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	PostalCode: {
		dataType: 'STRING',
		description: 'Zip/Postal Code of address',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	Rating: {
		dataType: 'STRING',
		description:
			'Categorization of how you rate this account, for example, Hot, Cold. Entry is selected from a picklist of available values, which are set by an administrator. Each picklist value can have up to 40 characters.',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	Salutation: {
		dataType: 'STRING',
		description: 'Salutation of the lead e.g. Mr,Ms',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	ShippingCity: {
		dataType: 'STRING',
		description: 'City portion of shipping address. Up to 20 characters are allowed.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	ShippingCountry: {
		dataType: 'STRING',
		description: 'Country portion of shipping address',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	ShippingPostalCode: {
		dataType: 'STRING',
		description: 'Zip or postal code portion of shipping address. Up to 20 characters are allowed.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	ShippingState: {
		dataType: 'STRING',
		description: 'State or province portion of shipping address.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	ShippingStreet: {
		dataType: 'STRING',
		description: 'Primary mailing or shipping street address of account. Up to 255 characters are allowed.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	Sic: {
		dataType: 'STRING',
		description:
			'Standard Industrial Classification code of the accountâ€™s main business categorization. Up to 10 characters are allowed in this field.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	State: { dataType: 'STRING', description: 'State', inputType: 'TEXT', inputValidation: '(^.*$)' },
	Status: { dataType: 'STRING', description: 'Status of the lead', inputType: 'SELECT', inputValidation: '(^.*$)' },
	Street: { dataType: 'STRING', description: 'Street of address', inputType: 'TEXT', inputValidation: '(^.*$)' },
	TickerSymbol: {
		dataType: 'STRING',
		description:
			'The abbreviation used to identify publicly traded shares of a particular stock. Also known as the stock symbol.',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
	Title: { dataType: 'STRING', description: 'Title of lead', inputType: 'TEXT', inputValidation: '(^.*$)' },
	Type: {
		dataType: 'STRING',
		description:
			'Type of account, for example, Customer, Competitor, or Partner. Entry is selected from a picklist of available values',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
	Website: {
		dataType: 'STRING',
		description:
			'URL of accountâ€™s website, for example, www.engati.com. Up to 255 characters are allowed in this field',
		inputType: 'TEXT',
		inputValidation: '(^.*$)',
	},
};

export const GORGIAS_PARAM_MAP_DATA = {
	'assignee_user::id': {
		dataType: 'LONG',
		description: t('common:select'),
		inputType: 'SELECT',
		inputValidation: '',
	},
	'customer::id': {
		dataType: 'LONG',
		description: t('common:select'),
		inputType: 'SELECT',
		inputValidation: '',
	},
	'customer::email': {
		dataType: 'STRING',
		description: t('common:select'),
		inputType: 'TEXT',
		inputValidation: '',
	},
	description: {
		dataType: 'STRING',
		description: t(`components:node.integrationNodes.placeholders.description`),
		inputType: 'TEXT',
	},
	channel: {
		dataType: 'STRING',
		description: t('common:select'),
		inputType: 'SELECT',
		inputValidation: '',
	},
	tags: {
		dataType: 'LIST',
		description: t(`components:node.integrationNodes.placeholders.tags`),
		inputType: 'TAGS',
		inputValidation: '',
	},
};

/**
 * these are required for saving data to BE
 * <field name> : <field data> as recieved in param_map from BE (based on integrationkey and workflowKey)
 * api (getConfigForWorkFlow/)>
 */
export const PARAM_MAP_RESPONSE_DATA: any = {
	...CALENDAR_PARAM_MAP_DATA,
	...SALESFORCE_PARAM_MAP_DATA,
	...GORGIAS_PARAM_MAP_DATA,
	id: {
		dataType: 'STRING',
		description: 'Specify a ticket id to be fetched',
		inputType: 'TEXT',
	},
	description: { dataType: 'STRING', description: 'Brief detail of the issue', inputType: 'TEXT' },
	due_by: { dataType: 'DATE', description: 'Resolution date (yyyy-mm-dd)', inputType: 'TEXT' },
	email: { dataType: 'STRING', description: 'Email of the contact', inputType: 'TEXT' },
	priority: { dataType: 'INTEGER', description: 'Specify a priority for ticket', inputType: 'SELECT' },
	product_id: { dataType: 'LONG', description: 'Select a product', inputType: 'SELECT' },
	source: { dataType: 'INTEGER', description: 'Source of the ticket', inputType: 'SELECT' },
	status: { dataType: 'INTEGER', description: 'Specify a status of ticket', inputType: 'SELECT' },
	subject: { dataType: 'STRING', description: 'Title/Topic of the issue', inputType: 'TEXT' },
	type: { dataType: 'STRING', description: 'Specify a Ticket type from dropdown', inputType: 'LIST_MAP' },
	responder_id: { dataType: 'LONG', description: 'Specify an agent from dropdown', inputType: 'SELECT' },
	fr_due_by: { dataType: 'DATE', description: 'First response date (yyyy-mm-dd)', inputType: 'TEXT' },
	phone: { dataType: 'STRING', description: 'Phone number of the contact', inputType: 'TEXT' },
	'ticket::assignee_id': { dataType: 'LONG', description: 'None Selected', inputType: 'SELECT' },
	'ticket::comment::body': { dataType: 'STRING', description: 'Brief detail of the issue', inputType: 'TEXT' },
	'ticket::priority': { dataType: 'STRING', description: 'None Selected', inputType: 'SELECT' },
	'ticket::requester::email': { dataType: 'STRING', description: 'Email of the contact', inputType: 'TEXT' },
	'ticket::requester::name': { dataType: 'STRING', description: 'Name of the contact', inputType: 'TEXT' },
	'ticket::subject': { dataType: 'STRING', description: 'Title/Topic of the issue', inputType: 'TEXT' },
	'ticket::type': { dataType: 'STRING', description: 'None Selected', inputType: 'SELECT' },
	'ticket::status': { dataType: 'STRING', description: 'None Selected', inputType: 'SELECT' },
	'ticket::due_at': { dataType: 'DATE', description: 'Resolution date (yyyy-mm-dd)', inputType: 'TEXT' },
	Body: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXTAREA',
		inputValidation: '(^.*$)',
	},
	From: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
		inputValidation: '(^[0-9]*|(\\{\\{[^\\{\\}]*\\}\\})|(\\{)$)',
	},
	To: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
		inputValidation: '(^[0-9]*|(\\{\\{[^\\{\\}]*\\}\\})|(\\{)$)',
	},
	content: {
		dataType: 'STRING',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXTAREA',
		inputValidation: '(^.*$)',
	},
	to: {
		dataType: 'LIST',
		description: 'Specify a Value or a Select an Attribute',
		inputType: 'TEXT',
		inputValidation:
			'(^((,?([0-9])*|(\\{\\{[^\\{\\}]*\\}\\})*,?)*|(,?([0-9])*|(\\{\\{[^\\{\\}]*\\}\\})*,?\\{?)*)$)',
	},
	sheetId: {
		dataType: 'STRING',
		dependentParam: 'LIST$$::values',
		inputType: 'SELECT',
	},
	spreadSheetId: {
		dataType: 'STRING',
		dependentParam: 'sheetId',
		inputType: 'SELECT',
	},
	'LIST$$::values': {
		dataType: 'LIST',
		dependentParam: 'LIST$$::values',
		inputType: 'TEXT',
	},
	lookupColumn: {
		dataType: 'INTEGER',
		dependentParam: 'lookupValue',
		inputType: 'SELECT',
	},
	lookupValue: {
		dataType: 'STRING',
		dependentParam: 'attributes',
		inputType: 'TEXT',
	},
	attributes: {
		dataType: 'LIST',
		dependentParam: 'attributes',
		inputType: 'TEXT',
		metadata: { disableMentio: true, processAttribute: true },
	},
	row: {
		dataType: 'LIST',
		dependentParam: 'row',
		inputType: 'TEXT',
	},
	flowKey: {
		dataType: 'STRING',
		description: 'Node to trigger for restarting flow',
		inputType: 'SELECT',
		inputValidation: '(^.*$)',
	},
};

export const SALESFORCE_RESPONSE_ATTR_DATA: any = {
	CASE_ORIGIN: { key: 'CASE_ORIGIN', label: 'Case origin', type: 'TEXT' },
	CASE_REASON: { key: 'CASE_REASON', label: 'Case reason', type: 'TEXT' },
	COMMENTS: { key: 'COMMENTS', label: 'Comments', type: 'ARRAY' },
	CONTACT_EMAIL: { key: 'CONTACT_EMAIL', label: 'Contact email', type: 'TEXT' },
	DESCRIPTION: { key: 'DESCRIPTION', label: 'Description', type: 'TEXT' },
	OWNER: { key: 'OWNER', label: 'Owner email', type: 'TEXT' },
	POTENTIAL_LIABILITY: { key: 'POTENTIAL_LIABILITY', label: 'Potential liability', type: 'TEXT' },
	PRIORITY: { key: 'PRIORITY', label: 'Priority', type: 'TEXT' },
	PRODUCT: { key: 'PRODUCT', label: 'Product', type: 'TEXT' },
	SLA_VIOLATION: { key: 'SLA_VIOLATION', label: 'SLA Violation', type: 'TEXT' },
	STATUS: { key: 'STATUS', label: 'Status', type: 'TEXT' },
	SUBJECT: { key: 'SUBJECT', label: 'Subject', ntype: 'TEXT' },
	SUPPLIED_EMAIL: { key: 'SUPPLIED_EMAIL', label: 'Supplied Email', type: 'TEXT' },
	TYPE: { key: 'TYPE', label: 'Type', type: 'TEXT' },
};

export const GORGIAS_RESPONSE_ATTR_DATA: any = {
	CUSTOMER_EMAIL: { key: 'CUSTOMER_EMAIL', type: 'TEXT', label: 'Customer Email' },
	CUSTOMER_NAME: { key: 'CUSTOMER_NAME', type: 'TEXT', label: 'Customer Name' },
	ASSIGNEE_USER: { key: 'ASSIGNEE_NAME', type: 'TEXT', label: 'Assignee Name' },
	ASSIGNEE_EMAIL: { key: 'ASSIGNEE_EMAIL', type: 'TEXT', label: 'Assignee Email' },
	CREATED_DATETIME: { key: 'CREATED_DATETIME', type: 'TEXT', label: 'Created Time' },
	CHANNELS: { key: 'CHANNELS', type: 'TEXT', label: 'Channel' },
	TAGS: { key: 'TAGS', type: 'TEXT', label: 'Tags' },
	URL: { key: 'URL', type: 'TEXT', label: 'URL' },
	MESSAGES: { key: 'MESSAGES', type: 'TEXT', label: 'Messages' },
};

/**
 * these are required for saving user entered response_attribute data to BE
 * <field name> : <field data> as recieved in response_attributes from BE (based on integrationkey and workflowKey)
 * api (getConfigForWorkFlow/)>
 */
export const RESPONSE_ATTR_DATA: any = {
	...SALESFORCE_RESPONSE_ATTR_DATA,
	ASSIGNEE: {
		key: 'ASSIGNEE',
		label: 'Assignee',
		type: 'TEXT',
	},
	CREATED_DATE: {
		key: 'CREATED_DATE',
		type: 'TEXT',
		label: 'Created date',
	},
	DESCRIPTION: { key: 'DESCRIPTION', type: 'TEXT', label: 'Description' },
	DUE_DATE: { key: 'DUE_DATE', type: 'TEXT', label: 'Due Date' },
	EMAIL: { key: 'EMAIL', type: 'TEXT', label: 'Email' },
	NAME: { key: 'NAME', type: 'TEXT', label: 'Requester Name' },
	PHONE: { key: 'PHONE', type: 'TEXT', label: 'Phone' },
	PRIORITY: { key: 'PRIORITY', type: 'TEXT', label: 'Priority' },
	STATUS: { key: 'STATUS', type: 'TEXT', label: 'Status' },
	SUBJECT: { key: 'SUBJECT', type: 'TEXT', label: 'Subject' },
	TYPE: { key: 'TYPE', type: 'TEXT', label: 'Type' },
	...GORGIAS_RESPONSE_ATTR_DATA,
};

/**
 * any response_attribute name to be sent to BE that are not user entered
 * <workflow_key>: [{<response attr details to send BE>}]
 */
export const DEFAULT_RESPONSE_ATTR: any = {
	CREATE_TICKET_FRESHDESK: [{ key: 'ID', label: 'Id', name: 'user.ticket.id', type: 'TEXT' }],
	CREATE_TICKET_ZENDESK: [{ key: 'ID', label: 'Id', name: 'user.ticket.id', type: 'TEXT' }],
	SALESFORCE_ADD_CASE: [{ key: 'ID', label: 'Case Number', name: 'user.ticket.id', type: 'TEXT' }],
};

/**
 * all fields for integration nodes go here (!! multiple nodes may share this data !!)
 *
 * fields data that is used to render the input, select etc boxes in the <Fields> component
 * <identifier_by_choice> : { label : <field label>, type: <field type>,
 *  mentions: <is ENGTMentions required in field>, name: <field name in form controller>, required: <is field required>,
 * inputValidation: <regex pattern that needs to be validated in field>,
 * validate: <validate function , if required>,
 * options: {options: [{id:'', label:''}], selectedValue: {id:'',label:''} (default value)}
 * regexErrorMsg: <error msg to be displayed if regex is not matched> }
 */
export const FIELD_DETAILS: (translate: any) => IObjProps = (translate: any) => ({
	retrieveAssignee: {
		label: translate(`components:node.integrationNodes.labels.assignee`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.ASSIGNEE.attribute_name',
	},
	retrieveCreatedDate: {
		label: translate(`components:node.integrationNodes.labels.createdDate`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.CREATED_DATE.attribute_name',
	},
	retrieveDescription: {
		label: translate(`components:node.integrationNodes.labels.description`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.DESCRIPTION.attribute_name',
	},
	retrieveDueDate: {
		label: translate(`components:node.integrationNodes.labels.dueDate`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.DUE_DATE.attribute_name',
	},
	retrieveEmail: {
		label: translate(`components:node.integrationNodes.labels.email`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.EMAIL.attribute_name',
	},
	retrieveName: {
		label: translate(`components:node.integrationNodes.labels.requesterName`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.NAME.attribute_name',
	},
	retrievePhone: {
		label: translate(`components:node.integrationNodes.labels.phone`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.PHONE.attribute_name',
	},
	retrievePriority: {
		label: translate(`components:node.integrationNodes.labels.priority`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.PRIORITY.attribute_name',
	},
	retrieveStatus: {
		label: translate(`components:node.integrationNodes.labels.status`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.STATUS.attribute_name',
	},
	retrieveSubject: {
		label: translate(`components:node.integrationNodes.labels.subject`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.SUBJECT.attribute_name',
	},
	retrieveType: {
		label: translate(`components:node.integrationNodes.labels.type`),
		type: 'Text',
		mentions: false,
		placeholder: translate(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.TYPE.attribute_name',
	},
	ticketId: {
		label: translate(`components:node.integrationNodes.labels.ticketId`),
		type: 'Text',
		mentions: true,
		placeholder: translate(`components:node.integrationNodes.placeholders.ticketId`),
		required: true,
		name: 'paramMap.id',
	},
	productId: {
		label: translate(`components:node.integrationNodes.labels.productId`),
		type: 'select',
		options: [],
		placeholder: translate(`components:node.integrationNodes.placeholders.productId`),
		required: false,
		name: 'paramMap.product_id',
	},
	email: {
		label: translate(`components:node.integrationNodes.labels.email`),
		type: 'Text',
		inputValidation: EMAIL_OR_ATTR_REGEX,
		mentions: true,
		placeholder: translate(`components:node.integrationNodes.placeholders.email`),
		required: true,
		name: 'paramMap.email',
	},
	name: {
		label: translate(`components:node.integrationNodes.labels.requesterName`),
		type: 'Text',
		mentions: true,
		placeholder: translate(`components:node.integrationNodes.placeholders.name`),
		required: true,
		name: 'paramMap.ticket::requester::name',
	},
	phone: {
		label: translate(`components:node.integrationNodes.labels.phone`),
		type: 'Text',
		mentions: true,
		placeholder: translate(`components:node.integrationNodes.placeholders.phone`),
		required: false,
		name: 'paramMap.phone',
	},
	subject: {
		label: translate(`components:node.integrationNodes.labels.subject`),
		type: 'Text',
		mentions: true,
		placeholder: translate(`components:node.integrationNodes.placeholders.subject`),
		required: true,
		name: 'paramMap.subject',
	},
	description: {
		label: translate(`components:node.integrationNodes.labels.description`),
		type: 'Text',
		mentions: true,
		placeholder: translate(`components:node.integrationNodes.placeholders.description`),
		required: true,
		name: 'paramMap.description',
	},
	type: {
		label: translate(`components:node.integrationNodes.labels.type`),
		type: 'select',
		options: [],
		placeholder: '',
		required: false,
		name: 'paramMap.type',
	},
	priority: {
		label: translate(`components:node.integrationNodes.labels.priority`),
		type: 'select',
		options: {
			options: STATIC_OPTIONS()['priority'],
			selectedValue: {
				id: 1,
				label: translate(`components:node.integrationNodes.labels.low`),
			},
		},
		placeholder: '',
		required: false,
		name: 'paramMap.priority',
	},
	status: {
		label: translate(`components:node.integrationNodes.labels.status`),
		type: 'select',
		options: {
			options: STATIC_OPTIONS()['status'],
			selectedValue: {
				id: 2,
				label: translate(`components:node.integrationNodes.labels.open`),
			},
		},
		placeholder: '',
		required: false,
		name: 'paramMap.status',
	},
	dueDate: {
		label: translate(`components:node.integrationNodes.labels.dueDate`),
		type: 'Text',
		mentions: true,
		inputValidation: DATE_OR_ATTR_REGEX,
		regexErrorMsg: translate(`components:node.integrationNodes.invalidDate`).toString(),
		placeholder: translate(`components:node.integrationNodes.placeholders.dueDate`),
		required: false,
		name: 'paramMap.due_by',
	},
	frDueDate: {
		label: translate(`components:node.integrationNodes.labels.frDueDate`),
		type: 'Text',
		mentions: true,
		inputValidation: DATE_OR_ATTR_REGEX,
		regexErrorMsg: translate(`components:node.integrationNodes.invalidDate`).toString(),
		placeholder: translate(`components:node.integrationNodes.placeholders.frDueDate`),
		required: false,
		name: 'paramMap.fr_due_by',
	},
	assignee: {
		label: translate(`components:node.integrationNodes.labels.assignee`),
		type: 'select',
		options: [],
		placeholder: '',
		required: false,
		name: 'paramMap.responder_id',
	},
	source: {
		label: translate(`components:node.integrationNodes.labels.source`),
		type: 'select',
		options: {
			options: STATIC_OPTIONS()['source'],
			selectedValue: {
				id: 2,
				label: translate(`components:node.integrationNodes.labels.portal`),
			},
		},
		placeholder: '',
		required: false,
		name: 'paramMap.source',
	},
	toTwilio: {
		label: translate(`components:node.integrationNodes.labels.to`),
		type: 'Text',
		placeholder: translate(`components:node.integrationNodes.placeholders.to`),
		tooltip: translate(`components:node.integrationNodes.tooltips.toTwilio`),
		inputValidation: PHONE_OR_ATTR_REGEX,
		mentions: true,
		required: true,
		name: 'paramMap.To',
	},
	from: {
		label: translate(`components:node.integrationNodes.labels.from`),
		type: 'Text',
		placeholder: translate(`components:node.integrationNodes.placeholders.from`),
		tooltip: translate(`components:node.integrationNodes.tooltips.fromTwilio`),
		inputValidation: PHONE_OR_ATTR_REGEX,
		mentions: true,
		required: true,
		name: 'paramMap.From',
	},
	body: {
		label: translate(`components:node.integrationNodes.labels.body`),
		type: 'textarea',
		placeholder: translate(`components:node.integrationNodes.placeholders.body`),
		tooltip: translate(`components:node.integrationNodes.tooltips.body`),
		mentions: true,
		required: true,
		name: 'paramMap.Body',
	},
	toClickatell: {
		label: translate(`components:node.integrationNodes.labels.to`),
		type: 'Text',
		placeholder: translate(`components:node.integrationNodes.placeholders.to`),
		tooltip: translate(`components:node.integrationNodes.tooltips.toClickatell`),
		inputValidation:
			/(^((,?([0-9])*|(\\{\\{[^\\{\\}]*\\}\\})*,?)*|(,?([0-9])*|(\\{\\{[^\\{\\}]*\\}\\})*,?\\{?)*)$)|{{.*}}/,
		mentions: true,
		required: true,
		name: 'paramMap.to',
	},
	content: {
		label: translate(`components:node.integrationNodes.labels.body`),
		type: 'textarea',
		placeholder: translate(`components:node.integrationNodes.placeholders.body`),
		tooltip: translate(`components:node.integrationNodes.tooltips.body`),
		mentions: true,
		required: true,
		name: 'paramMap.content',
	},
});

export const SALESFORCE_FIELDS: () => IObjProps = () => ({
	retrieveorigin: {
		label: t(`components:node.integrationNodes.salesforce.origin`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.CASE_ORIGIN.attribute_name',
	},
	retrieveReason: {
		label: t(`components:node.integrationNodes.salesforce.reason`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.CASE_REASON.attribute_name',
	},
	retrieveComments: {
		label: t(`components:node.integrationNodes.salesforce.comment`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.COMMENTS.attribute_name',
	},
	retrieveEmail: {
		label: t(`components:node.integrationNodes.salesforce.contactEmail`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.CONTACT_EMAIL.attribute_name',
	},
	retrieveDescription: {
		label: t(`components:node.integrationNodes.salesforce.descriptionAttr`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.DESCRIPTION.attribute_name',
	},
	retrieveOwnerEmail: {
		label: t(`components:node.integrationNodes.salesforce.ownerEmail`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.OWNER.attribute_name',
	},
	retrievePL: {
		label: t(`components:node.integrationNodes.salesforce.potentialLiability`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.POTENTIAL_LIABILITY.attribute_name',
	},
	retrievePriority: {
		label: t(`components:node.integrationNodes.salesforce.priority`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.PRIORITY.attribute_name',
	},
	retrieveProduct: {
		label: t(`components:node.integrationNodes.salesforce.product`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.PRODUCT.attribute_name',
	},
	retrieveSLA: {
		label: t(`components:node.integrationNodes.salesforce.slaViolation`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.SLA_VIOLATION.attribute_name',
	},
	retrieveStatus: {
		label: t(`components:node.integrationNodes.salesforce.status`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.STATUS.attribute_name',
	},
	retrieveSubject: {
		label: t(`components:node.integrationNodes.salesforce.subject`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.SUBJECT.attribute_name',
	},
	retrieveSuppEmail: {
		label: t(`components:node.integrationNodes.salesforce.suppliedEmail`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.SUPPLIED_EMAIL.attribute_name',
	},
	retrieveType: {
		label: t(`components:node.integrationNodes.salesforce.type`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.TYPE.attribute_name',
	},
	caseNumber: {
		label: t(`components:node.integrationNodes.salesforce.caseNumber`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.caseNumber`),
		mentions: true,
		required: true,
		name: 'paramMap.caseNumber',
	},
	CommentBody: {
		label: t(`components:node.integrationNodes.salesforce.comment`),
		type: 'textarea',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.comment`),
		mentions: true,
		required: false,
		name: 'paramMap.CommentBody',
	},
	ContactId: {
		label: t(`components:node.integrationNodes.salesforce.contact`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.ContactId',
	},
	Origin: {
		label: t(`components:node.integrationNodes.salesforce.origin`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Origin',
	},
	PotentialLiability__c: {
		label: t(`components:node.integrationNodes.salesforce.potentialLiability`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.PotentialLiability__c',
	},
	Priority: {
		label: t(`components:node.integrationNodes.salesforce.priority`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Priority',
	},
	Product__c: {
		label: t(`components:node.integrationNodes.salesforce.product`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Product__c',
	},
	Reason: {
		label: t(`components:node.integrationNodes.salesforce.reason`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Reason',
	},
	SLAViolation__c: {
		label: t(`components:node.integrationNodes.salesforce.slaViolation`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.SLAViolation__c',
	},
	Subject: {
		label: t(`components:node.integrationNodes.salesforce.subject`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.subject`),
		mentions: true,
		required: false,
		name: 'paramMap.Subject',
	},
	SuppliedEmail: {
		label: t(`components:node.integrationNodes.salesforce.suppliedEmail`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.email`),
		mentions: true,
		required: false,
		name: 'paramMap.SuppliedEmail',
	},
	AccountId: {
		label: t(`components:node.integrationNodes.salesforce.accountId`),
		type: 'select',
		options: [],
		required: true,
		name: 'paramMap.AccountId',
	},
	Amount: {
		label: t(`components:node.integrationNodes.salesforce.amount`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.amount`),
		mentions: true,
		required: false,
		name: 'paramMap.Amount',
	},
	CampaignId: {
		label: t(`components:node.integrationNodes.salesforce.campaign`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.CampaignId',
	},
	CloseDate: {
		label: t(`components:node.integrationNodes.salesforce.closeDate`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.closeDate`),
		mentions: true,
		required: true,
		inputValidation: DATE_OR_ATTR_REGEX,
		regexErrorMsg: t(`components:node.integrationNodes.invalidDate`).toString(),
		name: 'paramMap.CloseDate',
	},
	ForecastCategoryName: {
		label: t(`components:node.integrationNodes.salesforce.forecastCategory`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.ForecastCategoryName',
	},
	IsPrivate: {
		label: t(`components:node.integrationNodes.salesforce.isPrivate`),
		type: 'RADIO',
		radioOptions: [
			{
				name: t('components:node.integrationNodes.google_calendar.yes'),
				value: 'true',
			},
			{
				name: t('components:node.integrationNodes.google_calendar.no'),
				value: 'false',
			},
		],
		mentions: false,
		required: true,
		name: 'paramMap.IsPrivate',
	},
	NextStep: {
		label: t(`components:node.integrationNodes.salesforce.nextStep`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.nextStep`),
		mentions: true,
		required: false,
		name: 'paramMap.NextStep',
	},
	Probability: {
		label: t(`components:node.integrationNodes.salesforce.probability`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.probability`),
		mentions: true,
		required: false,
		name: 'paramMap.Probability',
	},
	StageName: {
		label: t(`components:node.integrationNodes.salesforce.stageName`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.StageName',
	},
	AccountNumber: {
		label: t(`components:node.integrationNodes.salesforce.accountNumber`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.accountNumber`),
		mentions: true,
		required: false,
		name: 'paramMap.AccountNumber',
	},
	AnnualRevenue: {
		label: t(`components:node.integrationNodes.salesforce.annualRevenue`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.annualRevenue`),
		mentions: true,
		required: false,
		name: 'paramMap.AnnualRevenue',
	},
	BillingCity: {
		label: t(`components:node.integrationNodes.salesforce.billingCity`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.billingCity`),
		mentions: true,
		required: false,
		name: 'paramMap.BillingCity',
	},
	BillingCountry: {
		label: t(`components:node.integrationNodes.salesforce.billingCountry`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.billingCountry`),
		mentions: true,
		required: false,
		name: 'paramMap.BillingCountry',
	},
	BillingPostalCode: {
		label: t(`components:node.integrationNodes.salesforce.billingPostalCode`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.billingPostalCode`),
		mentions: true,
		required: false,
		name: 'paramMap.BillingPostalCode',
	},
	BillingState: {
		label: t(`components:node.integrationNodes.salesforce.billingState`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.billingState`),
		mentions: true,
		required: false,
		name: 'paramMap.BillingState',
	},
	BillingStreet: {
		label: t(`components:node.integrationNodes.salesforce.billingStreet`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.billingStreet`),
		mentions: true,
		required: false,
		name: 'paramMap.BillingStreet',
	},
	City: {
		label: t(`components:node.integrationNodes.salesforce.city`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.city`),
		mentions: true,
		required: false,
		name: 'paramMap.City',
	},
	Company: {
		label: t(`components:node.integrationNodes.salesforce.company`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.company`),
		mentions: true,
		required: true,
		name: 'paramMap.Company',
	},
	Country: {
		label: t(`components:node.integrationNodes.salesforce.country`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.country`),
		mentions: true,
		required: false,
		name: 'paramMap.Country',
	},
	Description: {
		label: t(`components:node.integrationNodes.salesforce.accountDescription`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.accountDescription`),
		mentions: true,
		required: false,
		name: 'paramMap.Description',
	},
	Email: {
		label: t(`components:node.integrationNodes.salesforce.email`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.email`),
		mentions: true,
		required: false,
		name: 'paramMap.Email',
	},
	Employees: {
		label: t(`components:node.integrationNodes.salesforce.employees`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.employees`),
		mentions: true,
		required: false,
		name: 'paramMap.Employees',
	},
	Fax: {
		label: t(`components:node.integrationNodes.salesforce.fax`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.fax`),
		mentions: true,
		required: false,
		name: 'paramMap.Fax',
	},
	FirstName: {
		label: t(`components:node.integrationNodes.salesforce.firstName`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.firstName`),
		mentions: true,
		required: false,
		name: 'paramMap.FirstName',
	},
	Industry: {
		label: t(`components:node.integrationNodes.salesforce.industry`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Industry',
	},
	LastName: {
		label: t(`components:node.integrationNodes.salesforce.lastName`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.lastName`),
		mentions: true,
		required: true,
		name: 'paramMap.LastName',
	},
	LeadSource: {
		label: t(`components:node.integrationNodes.salesforce.source`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.LeadSource',
	},
	MobilePhone: {
		label: t(`components:node.integrationNodes.salesforce.mobilePhone`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.mobilePhone`),
		mentions: true,
		required: false,
		name: 'paramMap.MobilePhone',
	},
	NumberOfEmployees: {
		label: t(`components:node.integrationNodes.salesforce.employees`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.employees`),
		mentions: true,
		required: false,
		name: 'paramMap.NumberOfEmployees',
	},
	OwnerId: {
		label: t(`components:node.integrationNodes.salesforce.ownerId`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.OwnerId',
	},
	PostalCode: {
		label: t(`components:node.integrationNodes.salesforce.postalCode`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.postalCode`),
		mentions: true,
		required: false,
		name: 'paramMap.PostalCode',
	},
	Name: {
		label: t(`components:node.integrationNodes.salesforce.accountName`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.accountName`),
		mentions: true,
		required: true,
		name: 'paramMap.Name',
	},
	Ownership: {
		label: t(`components:node.integrationNodes.salesforce.ownership`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Ownership',
	},
	Phone: {
		label: t(`components:node.integrationNodes.salesforce.phone`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.phone`),
		mentions: true,
		required: false,
		name: 'paramMap.Phone',
	},
	Rating: {
		label: t(`components:node.integrationNodes.salesforce.rating`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Rating',
	},
	Salutation: {
		label: t(`components:node.integrationNodes.salesforce.salutation`),
		type: 'select',
		options: { options: STATIC_OPTIONS()['Salutation'] },
		required: false,
		name: 'paramMap.Salutation',
	},
	State: {
		label: t(`components:node.integrationNodes.salesforce.state`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.state`),
		mentions: true,
		required: false,
		name: 'paramMap.State',
	},
	Status: {
		label: t(`components:node.integrationNodes.salesforce.status`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Status',
	},
	Street: {
		label: t(`components:node.integrationNodes.salesforce.street`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.street`),
		mentions: true,
		required: false,
		name: 'paramMap.Street',
	},
	ShippingCity: {
		label: t(`components:node.integrationNodes.salesforce.shippingCity`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.shippingCity`),
		mentions: true,
		required: false,
		name: 'paramMap.ShippingCity',
	},
	ShippingCountry: {
		label: t(`components:node.integrationNodes.salesforce.shippingCountry`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.shippingCountry`),
		mentions: true,
		required: false,
		name: 'paramMap.ShippingCountry',
	},
	ShippingPostalCode: {
		label: t(`components:node.integrationNodes.salesforce.shippingPostalCode`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.shippingPostalCode`),
		mentions: true,
		required: false,
		name: 'paramMap.ShippingPostalCode',
	},
	ShippingState: {
		label: t(`components:node.integrationNodes.salesforce.shippingState`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.shippingState`),
		mentions: true,
		required: false,
		name: 'paramMap.ShippingState',
	},
	ShippingStreet: {
		label: t(`components:node.integrationNodes.salesforce.shippingStreet`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.shippingStreet`),
		mentions: true,
		required: false,
		name: 'paramMap.ShippingStreet',
	},
	Sic: {
		label: t(`components:node.integrationNodes.salesforce.sic`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.sic`),
		mentions: true,
		required: false,
		name: 'paramMap.Sic',
	},
	TickerSymbol: {
		label: t(`components:node.integrationNodes.salesforce.tickerSymbol`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.tickerSymbol`),
		mentions: true,
		required: false,
		name: 'paramMap.TickerSymbol',
	},
	Title: {
		label: t(`components:node.integrationNodes.salesforce.title`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.title`),
		mentions: true,
		required: false,
		name: 'paramMap.Title',
	},
	Type: {
		label: t(`components:node.integrationNodes.salesforce.type`),
		type: 'select',
		options: [],
		required: false,
		name: 'paramMap.Type',
	},
	Website: {
		label: t(`components:node.integrationNodes.salesforce.website`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.salesforce.placeholders.website`),
		mentions: true,
		required: false,
		name: 'paramMap.Website',
	},
});

export const SALESFORCE_ADD_ACCOUNT_FIELDS = () => [
	[
		SALESFORCE_FIELDS()['Name'],
		SALESFORCE_FIELDS()['Website'],
		SALESFORCE_FIELDS()['Phone'],
		SALESFORCE_FIELDS()['Fax'],
		SALESFORCE_FIELDS()['AccountNumber'],
		SALESFORCE_FIELDS()['Description'],
		SALESFORCE_FIELDS()['Type'],
		SALESFORCE_FIELDS()['Rating'],
		SALESFORCE_FIELDS()['Sic'],
		SALESFORCE_FIELDS()['Industry'],
		SALESFORCE_FIELDS()['AnnualRevenue'],
		SALESFORCE_FIELDS()['Ownership'],
		SALESFORCE_FIELDS()['TickerSymbol'],
		SALESFORCE_FIELDS()['Employees'],
		SALESFORCE_FIELDS()['BillingStreet'],
		SALESFORCE_FIELDS()['BillingCity'],
		SALESFORCE_FIELDS()['BillingPostalCode'],
		SALESFORCE_FIELDS()['BillingState'],
		SALESFORCE_FIELDS()['BillingCountry'],
		SALESFORCE_FIELDS()['ShippingStreet'],
		SALESFORCE_FIELDS()['ShippingCity'],
		SALESFORCE_FIELDS()['ShippingPostalCode'],
		SALESFORCE_FIELDS()['ShippingState'],
		SALESFORCE_FIELDS()['ShippingCountry'],
	],
];

export const SALESFORCE_ADD_LEAD_FIELDS = () => [
	[
		SALESFORCE_FIELDS()['Salutation'],
		SALESFORCE_FIELDS()['LastName'],
		SALESFORCE_FIELDS()['FirstName'],
		SALESFORCE_FIELDS()['Title'],
		SALESFORCE_FIELDS()['Company'],
		SALESFORCE_FIELDS()['Street'],
		SALESFORCE_FIELDS()['City'],
		SALESFORCE_FIELDS()['State'],
		SALESFORCE_FIELDS()['PostalCode'],
		SALESFORCE_FIELDS()['Country'],
		SALESFORCE_FIELDS()['Phone'],
		SALESFORCE_FIELDS()['MobilePhone'],
		SALESFORCE_FIELDS()['Fax'],
		SALESFORCE_FIELDS()['Email'],
		SALESFORCE_FIELDS()['Website'],
		SALESFORCE_FIELDS()['Description'],
		SALESFORCE_FIELDS()['LeadSource'],
		SALESFORCE_FIELDS()['Status'],
		SALESFORCE_FIELDS()['Industry'],
		SALESFORCE_FIELDS()['Rating'],
		SALESFORCE_FIELDS()['AnnualRevenue'],
		SALESFORCE_FIELDS()['NumberOfEmployees'],
		SALESFORCE_FIELDS()['OwnerId'],
	],
];

export const SALESFORCE_ADD_OPP_FIELDS = () => [
	[
		SALESFORCE_FIELDS()['AccountId'],
		SALESFORCE_FIELDS()['IsPrivate'],
		SALESFORCE_FIELDS()['Name'],
		SALESFORCE_FIELDS()['Description'],
		SALESFORCE_FIELDS()['StageName'],
		SALESFORCE_FIELDS()['Amount'],
		SALESFORCE_FIELDS()['Probability'],
		SALESFORCE_FIELDS()['CloseDate'],
		SALESFORCE_FIELDS()['Type'],
		SALESFORCE_FIELDS()['NextStep'],
		SALESFORCE_FIELDS()['LeadSource'],
		SALESFORCE_FIELDS()['ForecastCategoryName'],
		SALESFORCE_FIELDS()['CampaignId'],
		SALESFORCE_FIELDS()['OwnerId'],
	],
];

export const SALESFORCE_ADD_CASE_FIELDS = () => [
	[
		SALESFORCE_FIELDS()['Product__c'],
		SALESFORCE_FIELDS()['SuppliedEmail'],
		SALESFORCE_FIELDS()['ContactId'],
		SALESFORCE_FIELDS()['Subject'],
		SALESFORCE_FIELDS()['Description'],
		SALESFORCE_FIELDS()['CommentBody'],
		SALESFORCE_FIELDS()['Type'],
		SALESFORCE_FIELDS()['Priority'],
		SALESFORCE_FIELDS()['Status'],
		SALESFORCE_FIELDS()['OwnerId'],
		SALESFORCE_FIELDS()['Origin'],
		SALESFORCE_FIELDS()['Reason'],
		SALESFORCE_FIELDS()['SLAViolation__c'],
		SALESFORCE_FIELDS()['PotentialLiability__c'],
	],
];

export const SALESFORCE_UPDATE_CASE_FIELDS = () => [
	[
		SALESFORCE_FIELDS()['caseNumber'],
		SALESFORCE_FIELDS()['Product__c'],
		SALESFORCE_FIELDS()['SuppliedEmail'],
		SALESFORCE_FIELDS()['ContactId'],
		SALESFORCE_FIELDS()['Subject'],
		SALESFORCE_FIELDS()['Description'],
		SALESFORCE_FIELDS()['CommentBody'],
		SALESFORCE_FIELDS()['Type'],
		SALESFORCE_FIELDS()['Priority'],
		SALESFORCE_FIELDS()['Status'],
		SALESFORCE_FIELDS()['OwnerId'],
		SALESFORCE_FIELDS()['Origin'],
		SALESFORCE_FIELDS()['Reason'],
		SALESFORCE_FIELDS()['SLAViolation__c'],
		SALESFORCE_FIELDS()['PotentialLiability__c'],
	],
];

export const SALESFORCE_GET_CASE_FIELDS = () => [
	[SALESFORCE_FIELDS()['caseNumber']],
	[
		SALESFORCE_FIELDS()['retrieveorigin'],
		SALESFORCE_FIELDS()['retrieveReason'],
		SALESFORCE_FIELDS()['retrieveComments'],
		SALESFORCE_FIELDS()['retrieveEmail'],
		SALESFORCE_FIELDS()['retrieveDescription'],
		SALESFORCE_FIELDS()['retrieveOwnerEmail'],
		SALESFORCE_FIELDS()['retrievePL'],
		SALESFORCE_FIELDS()['retrievePriority'],
		SALESFORCE_FIELDS()['retrieveProduct'],
		SALESFORCE_FIELDS()['retrieveSLA'],
		SALESFORCE_FIELDS()['retrieveStatus'],
		SALESFORCE_FIELDS()['retrieveSubject'],
		SALESFORCE_FIELDS()['retrieveSuppEmail'],
		SALESFORCE_FIELDS()['retrieveType'],
	],
];

/**
 * these are all fields to be shown based on tabs
 * should be an array of arrays
 * [[<all fields to be shown under tab1>], [<all fields to be shown under tab2>] ...]
 */
export const FRESHDESK_GET_TICKET = (translate: any) => [
	[FIELD_DETAILS(translate)['ticketId']],
	[
		FIELD_DETAILS(translate)['retrieveAssignee'],
		FIELD_DETAILS(translate)['retrieveDescription'],
		FIELD_DETAILS(translate)['retrieveDueDate'],
		FIELD_DETAILS(translate)['retrieveEmail'],
		FIELD_DETAILS(translate)['retrievePhone'],
		FIELD_DETAILS(translate)['retrievePriority'],
		FIELD_DETAILS(translate)['retrieveStatus'],
		FIELD_DETAILS(translate)['retrieveSubject'],
		FIELD_DETAILS(translate)['retrieveType'],
	],
];

export const FRESHDESK_CREATE_TICKET = (translate: any) => [
	[
		FIELD_DETAILS(translate)['productId'],
		FIELD_DETAILS(translate)['email'],
		FIELD_DETAILS(translate)['phone'],
		FIELD_DETAILS(translate)['subject'],
		FIELD_DETAILS(translate)['description'],
		FIELD_DETAILS(translate)['type'],
		FIELD_DETAILS(translate)['priority'],
		FIELD_DETAILS(translate)['status'],
		FIELD_DETAILS(translate)['dueDate'],
		FIELD_DETAILS(translate)['frDueDate'],
		FIELD_DETAILS(translate)['assignee'],
		FIELD_DETAILS(translate)['source'],
	],
];

export const FRESHDESK_UPDATE_TICKET = (translate: any) => [
	[
		FIELD_DETAILS(translate)['ticketId'],
		{ ...FIELD_DETAILS(translate)['email'], required: false },
		FIELD_DETAILS(translate)['phone'],
		{ ...FIELD_DETAILS(translate)['subject'], required: false },
		{ ...FIELD_DETAILS(translate)['description'], required: false },
		FIELD_DETAILS(translate)['type'],
		FIELD_DETAILS(translate)['priority'],
		FIELD_DETAILS(translate)['status'],
		FIELD_DETAILS(translate)['dueDate'],
		FIELD_DETAILS(translate)['frDueDate'],
		FIELD_DETAILS(translate)['assignee'],
		FIELD_DETAILS(translate)['source'],
	],
];

export const ZENDESK_GET_TICKET = (translate: any) => [
	[FIELD_DETAILS(translate)['ticketId']],
	[
		FIELD_DETAILS(translate)['retrieveAssignee'],
		FIELD_DETAILS(translate)['retrieveCreatedDate'],
		FIELD_DETAILS(translate)['retrieveDescription'],
		FIELD_DETAILS(translate)['retrieveDueDate'],
		{
			...FIELD_DETAILS(translate)['retrieveEmail'],
			label: t('components:node.integrationNodes.labels.requesterEmail'),
		},
		FIELD_DETAILS(translate)['retrieveName'],
		FIELD_DETAILS(translate)['retrieveStatus'],
		FIELD_DETAILS(translate)['retrieveSubject'],
		FIELD_DETAILS(translate)['retrieveType'],
	],
];

export const ZENDESK_CREATE_TICKET = (translate: any) => [
	[
		FIELD_DETAILS(translate)['name'],
		{
			...FIELD_DETAILS(translate)['email'],
			label: translate('components:node.integrationNodes.labels.requesterEmail'),
			name: 'paramMap.ticket::requester::email',
		},
		{ ...FIELD_DETAILS(translate)['subject'], required: false, name: 'paramMap.ticket::subject' },
		{ ...FIELD_DETAILS(translate)['description'], name: 'paramMap.ticket::comment::body' },
		{
			...FIELD_DETAILS(translate)['type'],
			name: 'paramMap.ticket::type',
			options: { options: STATIC_OPTIONS()['ticket::type'] },
			isDependantField: true,
		},
		{
			...FIELD_DETAILS(translate)['priority'],
			name: 'paramMap.ticket::priority',
			options: { options: STATIC_OPTIONS()['ticket::priority'] },
		},
		{
			...FIELD_DETAILS(translate)['dueDate'],
			name: 'paramMap.ticket::due_at',
			renderCondition: {
				dependantField: 'paramMap.ticket::type',
				value: 'task',
				dependantParamFieldType: 'select',
			},
		},
		{ ...FIELD_DETAILS(translate)['assignee'], name: 'paramMap.ticket::assignee_id' },
	],
];

export const ZENDESK_UPDATE_TICKET = (translate: any) => [
	[
		FIELD_DETAILS(translate)['ticketId'],
		{ ...FIELD_DETAILS(translate)['name'], required: false },
		{
			...FIELD_DETAILS(translate)['email'],
			label: translate('components:node.integrationNodes.labels.requesterEmail'),
			name: 'paramMap.ticket::requester::email',
			required: false,
		},
		{ ...FIELD_DETAILS(translate)['subject'], required: false, name: 'paramMap.ticket::subject' },
		{
			...FIELD_DETAILS(translate)['description'],
			name: 'paramMap.ticket::comment::body',
			required: false,
			label: t('components:node.integrationNodes.labels.comment'),
		},
		{
			...FIELD_DETAILS(translate)['type'],
			name: 'paramMap.ticket::type',
			options: { options: STATIC_OPTIONS()['ticket::type'] },
			isDependantField: true,
		},
		{
			...FIELD_DETAILS(translate)['priority'],
			name: 'paramMap.ticket::priority',
			options: { options: STATIC_OPTIONS()['ticket::priority'] },
		},
		{
			...FIELD_DETAILS(translate)['status'],
			name: 'paramMap.ticket::status',
			options: { options: STATIC_OPTIONS()['ticket::status'] },
		},
		{
			...FIELD_DETAILS(translate)['dueDate'],
			name: 'paramMap.ticket::due_at',
			renderCondition: {
				dependantField: 'paramMap.ticket::type',
				value: 'task',
				dependantParamFieldType: 'select',
			},
		},
		{ ...FIELD_DETAILS(translate)['assignee'], name: 'paramMap.ticket::assignee_id' },
	],
];

export const SEND_SMS_TWILIO = (translate: any) => [
	[FIELD_DETAILS(translate)['toTwilio'], FIELD_DETAILS(translate)['from'], FIELD_DETAILS(translate)['body']],
];
export const SEND_SMS_CLICKATELL = (translate: any) => [
	[FIELD_DETAILS(translate)['toClickatell'], FIELD_DETAILS(translate)['content']],
];

export const SMS_OPTIONS = () => [
	{ id: 'SMS_CLICKATELL', label: t('components:node.integrationNodes.labels.smsClickatell') },
	{ id: 'SMS_TWILIO', label: t('components:node.integrationNodes.labels.smsTwilio') },
];

export const CALENDAR_FIELD_DETAILS: () => IObjProps = () => ({
	flowKey: {
		label: t(`components:node.integrationNodes.google_calendar.nodeToTrigger`),
		type: 'select',
		options: [],
		mentions: false,
		required: false,
		name: 'paramMap.flowKey',
	},
	showTimeZone: {
		label: t(`components:node.integrationNodes.google_calendar.showTimezone`),
		type: 'RADIO',
		radioOptions: [
			{
				name: t('components:node.integrationNodes.google_calendar.yes'),
				value: 'true',
			},
			{
				name: t('components:node.integrationNodes.google_calendar.no'),
				value: 'false',
			},
		],
		mentions: false,
		required: true,
		name: 'paramMap.show_time_zone',
	},
	slotDurationHour: {
		label: t(`components:node.integrationNodes.google_calendar.slotDurationHr`),
		type: 'select',
		options: [],
		required: true,
		name: 'paramMap.slot_duration_hh',
	},
	slotDurationMin: {
		label: t(`components:node.integrationNodes.google_calendar.slotDurationMin`),
		type: 'select',
		options: [],
		required: true,
		name: 'paramMap.slot_duration_mm',
	},
	noOfSlots: {
		label: t(`components:node.integrationNodes.google_calendar.noOfOptions`),
		type: 'select',
		options: [],
		required: true,
		name: 'paramMap.options_num',
	},
	datePreference: {
		label: t(`components:node.integrationNodes.google_calendar.datePreference`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: false,
		name: 'paramMap.appt_date',
	},
	timezone: {
		label: t(`components:node.integrationNodes.google_calendar.timezone`),
		type: 'select',
		options: [],
		searchable: true,
		required: true,
		name: 'paramMap.time_zone',
	},
	timePrefefernce: {
		label: t(`components:node.integrationNodes.google_calendar.timePreference`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: false,
		name: 'paramMap.time_slot_pref',
	},
	startTimeAttr: {
		label: t(`components:node.integrationNodes.google_calendar.startTimeAttr`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: true,
		name: 'paramMap.start_time_attr',
	},
	endTimeAttr: {
		label: t(`components:node.integrationNodes.google_calendar.endTimeAttr`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: true,
		name: 'paramMap.end_time_attr',
	},
	calendarId: {
		label: t(`components:node.integrationNodes.google_calendar.calendar`),
		type: 'select',
		placeholder: t(`common:select`),
		options: [],
		required: true,
		name: 'paramMap.calendarId',
	},
	workStartTime: {
		label: t(`components:node.integrationNodes.google_calendar.workStartTime`),
		type: 'Text',
		defaultValue: '9:00',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: false,
		name: 'paramMap.timeMin',
	},
	workEndTime: {
		label: t(`components:node.integrationNodes.google_calendar.workEndTime`),
		type: 'Text',
		defaultValue: '18:00',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: false,
		name: 'paramMap.timeMax',
	},
	showMoreOptions: {
		label: t(`components:node.integrationNodes.google_calendar.showMoreOptions`),
		type: 'RADIO',
		radioOptions: [
			{
				name: t('components:node.integrationNodes.google_calendar.yes'),
				value: 'true',
			},
			{
				name: t('components:node.integrationNodes.google_calendar.no'),
				value: 'false',
			},
		],
		mentions: false,
		required: true,
		name: 'paramMap.show_more',
	},
	showMoreOptionsLabel: {
		label: t(`components:node.integrationNodes.google_calendar.showMoreOptionsLabel`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: false,
		name: 'paramMap.show_more_label',
	},
	attendee: {
		label: t(`components:node.integrationNodes.google_calendar.attendeeEmail`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: true,
		name: 'paramMap.attendee',
	},
	startTime: {
		label: t(`components:node.integrationNodes.google_calendar.startTime`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: true,
		name: 'paramMap.startTime',
	},
	endTime: {
		label: t(`components:node.integrationNodes.google_calendar.endTime`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: true,
		name: 'paramMap.endTime',
	},
	title: {
		label: t(`components:node.integrationNodes.google_calendar.summary`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: false,
		name: 'paramMap.summary',
	},
	desc: {
		label: t(`components:node.integrationNodes.google_calendar.desc`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: false,
		name: 'paramMap.desc',
	},
	location: {
		label: t(`components:node.integrationNodes.google_calendar.location`),
		type: 'Text',
		placeholder: t(`components:node.integrationNodes.placeholders.valOrAttrName`),
		mentions: true,
		required: false,
		name: 'paramMap.location',
	},
	sendNotifications: {
		label: t(`components:node.integrationNodes.google_calendar.sendNotifications`),
		type: 'CHECKBOX',
		defaultValue: true,
		mentions: false,
		required: true,
		name: 'paramMap.sendNotifications',
	},

	sendMeetLink: {
		label: t(`components:node.integrationNodes.google_calendar.sendMeetLink`),
		type: 'CHECKBOX',
		defaultValue: false,
		mentions: false,
		required: true,
		name: 'paramMap.sendMeetLink',
	},
});

export const GET_CALENDAR_EVENTS = () => [
	[
		CALENDAR_FIELD_DETAILS()['showTimeZone'],
		CALENDAR_FIELD_DETAILS()['slotDurationHour'],
		CALENDAR_FIELD_DETAILS()['slotDurationMin'],
		CALENDAR_FIELD_DETAILS()['noOfSlots'],
		CALENDAR_FIELD_DETAILS()['datePreference'],
		CALENDAR_FIELD_DETAILS()['timezone'],
		CALENDAR_FIELD_DETAILS()['timePrefefernce'],
		CALENDAR_FIELD_DETAILS()['startTimeAttr'],
		CALENDAR_FIELD_DETAILS()['endTimeAttr'],
	],
	[
		CALENDAR_FIELD_DETAILS()['calendarId'],
		CALENDAR_FIELD_DETAILS()['workStartTime'],
		CALENDAR_FIELD_DETAILS()['workEndTime'],
	],
	[
		CALENDAR_FIELD_DETAILS()['showMoreOptions'],
		CALENDAR_FIELD_DETAILS()['showMoreOptionsLabel'],
		CALENDAR_FIELD_DETAILS()['flowKey'],
	],
];

export const CREATE_CALENDAR_EVENT = () => [
	[
		CALENDAR_FIELD_DETAILS()['calendarId'],
		CALENDAR_FIELD_DETAILS()['attendee'],
		CALENDAR_FIELD_DETAILS()['startTime'],
		CALENDAR_FIELD_DETAILS()['endTime'],
		CALENDAR_FIELD_DETAILS()['title'],
		CALENDAR_FIELD_DETAILS()['desc'],
		CALENDAR_FIELD_DETAILS()['location'],
		CALENDAR_FIELD_DETAILS()['sendNotifications'],
		CALENDAR_FIELD_DETAILS()['sendMeetLink'],
	],
];

export const GORGIAS_FIELD_DETAILS: () => IObjProps = () => ({
	retrieveCustomerName: {
		label: t(`components:node.integrationNodes.labels.customerName`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.CUSTOMER_NAME.attribute_name',
	},
	retrieveCustomerEmail: {
		label: t(`components:node.integrationNodes.labels.customerEmail`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.CUSTOMER_EMAIL.attribute_name',
	},
	retrieveAssigneeName: {
		label: t(`components:node.integrationNodes.labels.assigneeName`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.ASSIGNEE_USER.attribute_name',
	},
	retrieveAssigneeEmail: {
		label: t(`components:node.integrationNodes.labels.assigneeEmail`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.ASSIGNEE_EMAIL.attribute_name',
	},
	retrieveCreatedTime: {
		label: t(`components:node.integrationNodes.labels.createdDate`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.CREATED_DATETIME.attribute_name',
	},
	retrieveChannel: {
		label: t(`components:node.integrationNodes.labels.channel`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.CHANNELS.attribute_name',
	},
	retrieveTags: {
		label: t(`components:node.integrationNodes.labels.tags`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.TAGS.attribute_name',
	},
	retrieveUrl: {
		label: t(`components:node.integrationNodes.labels.url`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.URL.attribute_name',
	},
	retrieveMessages: {
		label: t(`components:node.integrationNodes.labels.messages`),
		type: 'Text',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.retrieveAttributeName`),
		required: false,
		name: 'responseAttributes.MESSAGES.attribute_name',
	},
	customerName: {
		label: t(`components:node.integrationNodes.labels.customer`),
		type: 'select',
		options: [],
		mentions: false,
		placeholder: t('common:select'),
		required: true,
		name: 'paramMap.customer::id',
	},
	customerEmail: {
		label: t(`components:node.integrationNodes.labels.customerEmail`),
		type: 'Text',
		mentions: false,
		placeholder: t('common:select'),
		required: true,
		name: 'paramMap.customer::email',
	},
	assigneeName: {
		label: t(`components:node.integrationNodes.labels.assignee`),
		type: 'select',
		options: [],
		mentions: false,
		placeholder: t('common:select'),
		required: false,
		name: 'paramMap.assignee_user::id',
	},
	status: {
		label: t(`components:node.integrationNodes.labels.status`),
		type: 'select',
		options: {
			options: STATIC_OPTIONS()['gorgiasStatus'],
			selectedValue: {
				id: 'open',
				label: t(`components:node.integrationNodes.labels.open`),
			},
		},
		placeholder: '',
		required: false,
		name: 'paramMap.status',
	},
	channel: {
		label: t(`components:node.integrationNodes.labels.channel`),
		type: 'select',
		options: {
			options: STATIC_OPTIONS()['channel'],
			selectedValue: {
				id: 'api',
				label: t(`components:node.integrationNodes.gorgias.api`),
			},
		},
		placeholder: t('common:select'),
		required: false,
		name: 'paramMap.channel',
	},
	tags: {
		label: t(`components:node.integrationNodes.labels.tags`),
		type: 'Tags',
		mentions: false,
		placeholder: t(`components:node.integrationNodes.placeholders.tags`),
		required: false,
		name: 'paramMap.tags',
	},
});

export const GORGIAS_GET_TICKET = (translate: any) => [
	[FIELD_DETAILS(translate)['ticketId']],
	[
		GORGIAS_FIELD_DETAILS()['retrieveCustomerName'],
		GORGIAS_FIELD_DETAILS()['retrieveCustomerEmail'],
		FIELD_DETAILS(translate)['retrieveSubject'],
		FIELD_DETAILS(translate)['retrieveStatus'],
		GORGIAS_FIELD_DETAILS()['retrieveAssigneeName'],
		GORGIAS_FIELD_DETAILS()['retrieveAssigneeEmail'],
		GORGIAS_FIELD_DETAILS()['retrieveCreatedTime'],
		GORGIAS_FIELD_DETAILS()['retrieveChannel'],
		GORGIAS_FIELD_DETAILS()['retrieveTags'],
		GORGIAS_FIELD_DETAILS()['retrieveUrl'],
		GORGIAS_FIELD_DETAILS()['retrieveMessages'],
	],
];

export const GORGIAS_CREATE_TICKET = (translate: any) => [
	[
		GORGIAS_FIELD_DETAILS()['customerName'],
		{
			...FIELD_DETAILS(translate)['subject'],
			placeholder: t(`components:node.integrationNodes.placeholders.gorgiasSubject`),
		},
		{
			...FIELD_DETAILS(translate)['description'],
			placeholder: t(`components:node.integrationNodes.placeholders.gorgiasDescription`),
		},
		GORGIAS_FIELD_DETAILS()['status'],
		GORGIAS_FIELD_DETAILS()['assigneeName'],
		GORGIAS_FIELD_DETAILS()['channel'],
		GORGIAS_FIELD_DETAILS()['tags'],
	],
];

export const GORGIAS_UPDATE_TICKET = (translate: any) => [
	[
		FIELD_DETAILS(translate)['ticketId'],
		{ ...GORGIAS_FIELD_DETAILS()['customerName'], required: false },
		{
			...FIELD_DETAILS(translate)['subject'],
			placeholder: t(`components:node.integrationNodes.placeholders.gorgiasSubject`),
			required: false,
		},
		GORGIAS_FIELD_DETAILS()['status'],
		GORGIAS_FIELD_DETAILS()['assigneeName'],
		GORGIAS_FIELD_DETAILS()['channel'],
		GORGIAS_FIELD_DETAILS()['tags'],
	],
];

export enum SEND_EMAIL_MODE {
	EXISTING_CONVERSATION = 'existing_conversation',
	NEW_CONVERSATION = 'new_conversation',
}

export const IntegrationApps: any = {
	SHOPIFY: 'Shopify',
	WOOCOMMERCE: 'WooCommerce',
	SHIPROCKET: 'Shiprocket',
	RAZORPAY: 'Razorpay',
	ZENDESK: 'Zendesk',
	ZOHO: 'Zoho CRM',
	FRESHDESK: 'Freshdesk',
	SMS: 'SMS',
	GOOGLE_SHEETS: 'Google Sheets',
	GOOGLE_CALENDAR: 'Google Calendar',
	GOOGLE_ANALYTICS: 'Google Analytics',
	GOOGLE_ADMIN: 'Google Admin',
	SALESFORCE: 'Salesforce',
	GOOGLE_TRANSLATE: 'Google Translate',
	GORGIAS: 'Gorgias',
	SALESTRAIL: 'Salestrail',
	ESENSEGPT: 'eSenseGPT',
	WA_PAYMENT: 'WhatsApp Payment',
	CLEVERTAP: 'CleverTap',
	MOENGAGE: 'MoEngage',
};

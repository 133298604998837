import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { PLACEMENT, StatefulTooltip, TRIGGER_TYPE } from 'baseui/tooltip';

interface IENGTTooltipProps {
	children: JSX.Element | string;
	className?: string;
	content: string | React.ReactElement;
	ignoreBoundary?: boolean;
	placement?: keyof PLACEMENT;
	type?: keyof TRIGGER_TYPE;
	usage?: 'channel' | 'video' | 'default' | 'list' | 'registration';
	overrides?: any;
	isNestedTooltipHovered?: boolean;
	setNestedTooltipHovered?: Function;
}

function ENGTTooltip(props: IENGTTooltipProps) {
	const [css, theme]: any = useStyletron();
	const {
		children,
		content,
		ignoreBoundary,
		placement,
		type,
		usage,
		overrides,
		setNestedTooltipHovered,
		isNestedTooltipHovered,
		...rest
	} = props;

	const getTooltipWidth = () => {
		if (usage === 'channel') {
			return '30%';
		}
		if (usage === 'list') {
			return '24rem';
		}

		if (usage === 'registration') {
			return '15.375rem';
		}

		return '12rem';
	};

	const getBackgroundColour = () => {
		if (usage === 'channel') {
			return theme.inputFillPrimary;
		}
		if (usage === 'video') {
			return theme.videoLayoutColours.tooltipBackgroundColor;
		}

		return theme.colors.primaryA;
	};

	const overrideProps = {
		Arrow: {
			style: {
				borderTopLeftRadius: '3px',
				borderBottomLeftRadius: '3px',
				backgroundColor: getBackgroundColour(),
			},
		},
		Body: {
			style: ({ $theme }: any) => ({
				maxWidth: getTooltipWidth(),
				marginTop: usage === 'channel' ? '1rem' : '',
				borderTopRightRadius: '6px',
				borderTopLeftRadius: '6px',
				borderBottomRightRadius: '6px',
				borderBottomLeftRadius: '6px',
				zIndex: $theme.zIndexToolTip,
				backgroundColor: getBackgroundColour(),
				wordBreak: 'break-all' as const,
				...overrides?.Body.style,
			}),
		},
		Inner: {
			style: {
				borderTopRightRadius: '6px',
				borderTopLeftRadius: '6px',
				borderBottomRightRadius: '6px',
				borderBottomLeftRadius: '6px',
				backgroundColor: getBackgroundColour(),
				paddingLeft: usage === 'channel' ? '0px' : '.625rem',
				paddingTop: usage === 'channel' ? '0px' : '.25rem',
				paddingRight: usage === 'channel' ? '0px' : '.625rem',
				paddingBottom: usage === 'channel' ? '0px' : '.25rem',
			},
		},
	};

	return (
		<StatefulTooltip
			accessibilityType='tooltip'
			triggerType={type}
			showArrow
			placement={placement}
			ignoreBoundary={ignoreBoundary}
			onOpen={() => setNestedTooltipHovered && setNestedTooltipHovered(true)}
			onClose={() => setNestedTooltipHovered && setNestedTooltipHovered(false)}
			content={
				!isNestedTooltipHovered ? (
					<Block
						color={usage === 'video' ? theme.videoLayoutColours.tooltipTextColor : theme.primaryB}
						className={css({
							wordBreak: 'break-word',
						})}
					>
						{content}
					</Block>
				) : (
					''
				)
			}
			overrides={overrideProps}
			{...rest}
		>
			{children}
		</StatefulTooltip>
	);
}

ENGTTooltip.defaultProps = {
	ignoreBoundary: false,
	type: TRIGGER_TYPE.hover,
	placement: PLACEMENT.right,
	usage: 'default',
};

export default ENGTTooltip;

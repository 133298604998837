import { PLACEMENT } from 'baseui/tooltip';

import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';

function AutoRetryStatusIcon({
	fillColorForText,
	fillColorForImage,
	statusMessage,
	toolTipMsg,
}: {
	fillColorForText?: string;
	fillColorForImage?: string;
	statusMessage?: string;
	toolTipMsg?: any;
}) {
	return (
		<ENGTTooltip content={toolTipMsg} ignoreBoundary={false} placement={PLACEMENT.left}>
			<svg width='80' height='20' viewBox='0 0 80 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M0 4C0 1.79086 1.79086 0 4 0H84C86.2091 0 88 1.79086 88 4V16C88 18.2091 86.2091 20 84 20H4C1.79086 20 0 18.2091 0 16V4Z'
					fill={fillColorForImage}
					opacity='0.2'
				/>
				<text
					x='50%'
					y='50%'
					dominantBaseline='middle'
					textAnchor='middle'
					fill={fillColorForText}
					fontSize='10'
				>
					{statusMessage}
				</text>
			</svg>
		</ENGTTooltip>
	);
}

export default AutoRetryStatusIcon;
